import { connect } from 'react-redux';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import i18n from './../../../i18n';
import sessionStore from './sessionStoreComponent';
import ExecuteArgs from '../../shared/model/executeArgs';
import * as actionsCommon from '../../shared/store/actions/index';
import commonConfig from '../commonConfig';
import CommonFuncs from './markersCommonFunctionComponent';
import { hostArray } from '../../../hostConfig';
import _ from 'lodash';
import CryptoAES from 'crypto-js/aes';
let moment = require('moment');
//import CryptoENC from 'crypto-js/enc-utf8';
const commonFuncObj = new CommonFuncs();
var SECRET_KEY = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';
class MarkersMenuAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relativeUrl: '',
            redirectStatus: false,
            getStateReportFlag: false,
            getStateFieldViewFlag: false,
            getResStateFieldViewFlag: false,
            schStateFieldViewResult: false,
            resStateFieldViewResult: false
        }
    }
    //Nav Active inactive.
    getNavLinkClass = (pathArr, path, exact = true) => {
        var isActive = pathArr.includes(path);
        return isActive === true ? 'active' : '';
    }
    collapseClass = (pathArr, path) => {
        if (!Array.isArray(pathArr)) {
            pathArr = pathArr.split(",");
        }
        var collapse = pathArr.includes(path);
        return collapse === true ? 'collapse show' : 'collapse';
    }
    activeLinkClass = (activeRoute, currentUrl) => {
        return activeRoute === currentUrl ? 'active' : '';
    }
    returnSessionItems(serviceName = '') {
        //CryptoAES.decrypt(schoolId.toString(), SECRET_KEY).toString(CryptoENC);
        return 'token=' + new sessionStore().returnSessionToken() + '&l=' + new sessionStore().returnLanguage() + '&sc=' + CryptoAES.encrypt(new sessionStore().returnSchoolID().toString(), SECRET_KEY) +
            '&se=' + CryptoAES.encrypt(new sessionStore().returnSessionID().toString(), SECRET_KEY) + '&d=' + CryptoAES.encrypt(new sessionStore().returnDistrictID().toString(), SECRET_KEY) +
            '&sy=' + CryptoAES.encrypt(new sessionStore().returnSchoolYear().toString(), SECRET_KEY) + '&m=' + CryptoAES.encrypt(new sessionStore().returnMarkingperiodId().toString(), SECRET_KEY) + '&r=' + CryptoAES.encrypt(new sessionStore().returnUserRole().toString(), SECRET_KEY) +
            '&st=' + CryptoAES.encrypt(new sessionStore().returnStaffID().toString(), SECRET_KEY) + '&u=' + CryptoAES.encrypt(new sessionStore().returnUserID().toString(), SECRET_KEY) +
            '&mc=' + CryptoAES.encrypt(new sessionStore().returnCurrentMenuCode(), SECRET_KEY) +
            '&un=' + CryptoAES.encrypt(new sessionStore().returnUserName(), SECRET_KEY) +
            '&ri=' + CryptoAES.encrypt(new sessionStore().returnUserRoleID().toString(), SECRET_KEY) +
            '&ev=' + CryptoAES.encrypt(new sessionStore().returnHostEnvironment().toString(), SECRET_KEY) +
            '&stu=' + CryptoAES.encrypt(new sessionStore().returnStudentID().toString(), SECRET_KEY) +
            '&ms=' + CryptoAES.encrypt(serviceName, SECRET_KEY) +
            '&sed=' + CryptoAES.encrypt(new sessionStore().returnCurrentSchoolSessionEndDate().toString(), SECRET_KEY) +
            '&sdf=' + CryptoAES.encrypt(new sessionStore().returnDateFormat().toString(), SECRET_KEY) +
            '&lue=' + CryptoAES.encrypt(new sessionStore().returnLoggedUserEmail(), SECRET_KEY)
    }
    checkLikeArrayValues = (array, moduleKey) => {
        let flag = false;
        for (var i = 0; i < array.length; i++) {
            if (array[i].indexOf(moduleKey) !== -1) {
                flag = true;
                break;
            } else {
                flag = false;
            }
        }
        return flag;
    }
    handleMenuClick = (e, relativeUrl, menuCode) => {
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let currentPagePermissions = { viewPermission: false, editPermission: false, deletePermission: false, approvalPermission: false, verifyPermission: false, certifyPermission: false, publicationPermission: false };
        let checkCurrentUserRoleBySchool = _.find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
        if (!!menuCode && !!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') {
            if (menuCode === 'STUD_GENERAL') {
                menuCode = 'STUD_GEN_INFO';
            }
            if (menuCode === "SCHL_LIST") {
                menuCode = "SCHL_GEN_INFO";
            }
            if (permissionList && permissionList.length > 0) {
                if (permissionList.includes(menuCode + "_VIEW")) {
                    currentPagePermissions.viewPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT")) {
                    currentPagePermissions.editPermission = true;
                }
                if (permissionList.includes(menuCode + "_DELETE")) {
                    currentPagePermissions.deletePermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_APPR")) {
                    currentPagePermissions.approvalPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_VERY")) {
                    currentPagePermissions.verifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_CERT")) {
                    currentPagePermissions.certifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_PUBL")) {
                    currentPagePermissions.publicationPermission = true;
                }
            }
        } else {
            let isAdminPrevSchlSessPermAccess = true;
            let adminPrevSessionPermissionList = new sessionStore().returnAdminSchlPrevSessionPermissionList();   //returns permission list for admin with respect to modifying/Add data in previous sessions
            let checkCurrentSchoolPermissionForAdmin = _.find(adminPrevSessionPermissionList, ['schoolId', new sessionStore().returnSchoolID()]);
            if (new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_ADMIN && !!checkCurrentSchoolPermissionForAdmin) {
                let currentSchoolSessionEndDate = new sessionStore().returnCurrentSchoolSessionEndDate();
                let isPrevSchoolSessionSelected = moment().format("YYYY-MM-DD") > currentSchoolSessionEndDate;
                isAdminPrevSchlSessPermAccess = !!isPrevSchoolSessionSelected ? !!checkCurrentSchoolPermissionForAdmin.isEdit : true;
            }
            currentPagePermissions.viewPermission = true;
            currentPagePermissions.editPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.deletePermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.approvalPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.verifyPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.certifyPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.publicationPermission = isAdminPrevSchlSessPermAccess;
        }
        new sessionStore().removeSessionItem(commonConfig.SESSION_CURRENT_PAGE_PERMISSION);
        new sessionStore().removeSessionItem(commonConfig.SESSION_MENU_CODE);
        new sessionStore().removeSessionItem(commonConfig.SESSION_MANAGE_INCIDENT_TYPE);
        new sessionStore().storeSecureData(commonConfig.SESSION_MENU_CODE, menuCode);
        new sessionStore().storeSecureData(commonConfig.SESSION_CURRENT_PAGE_PERMISSION, JSON.stringify(currentPagePermissions));
        if (menuCode === 'DASH_MAIN') {
            relativeUrl = '/dashboard';
        }
        if (menuCode === 'DIST_ADD_DIST') {
            this.props.onCommonResetProps({ name: 'getStateFieldView', data: {} });
            new sessionStore().storeSecureData(commonConfig.SESSION_DISTRICT_SR_SHOW, 0);
            this.setState({ getStateReportFlag: true, relativeUrl: relativeUrl }, () => {
                new sessionStore().storeSecureData('markersNotificationStatus', commonConfig.IS_HIDE);
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STATEREPORTING,
                    commonConfig.COMPONENT_SR_DISPLAY_STATE_REPORT_COMPONENT + new sessionStore().returnStateCode() + "&entityKey=" + commonConfig.STATE_ORG_LEA_SR + "&schoolId=" + new sessionStore().returnSchoolID() + "&districtId=" + new sessionStore().returnDistrictID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllSuccess(executedArgument, 'stateFieldView');
            })
        }
        else if (menuCode === "SCHL_LIST" || menuCode === "SCHL_GEN_INFO") {
            // this.props.onCommonResetProps({ name: 'getSchStateFieldView', data: null });
            // this.props.onCommonResetProps({ name: 'getResStateFieldView', data: null });
            new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_SR_SHOW, 0);
            new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_RES_SR_SHOW, 0);
            new sessionStore().storeSecureData('markersNotificationStatus', commonConfig.IS_HIDE);
            this.setState({ relativeUrl: relativeUrl, schStateFieldViewResult: true, resStateFieldViewResult: true }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STATEREPORTING,
                    commonConfig.COMPONENT_SR_DISPLAY_STATE_REPORT_COMPONENT + new sessionStore().returnStateCode() + "&entityKey=" + commonConfig.STATE_ORG_SCH_SR + "&schoolId=" + new sessionStore().returnSchoolID() + "&districtId=" + new sessionStore().returnDistrictID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllSuccess(executedArgument, 'schStateFieldView');
                executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STATEREPORTING,
                    commonConfig.COMPONENT_SR_DISPLAY_STATE_REPORT_COMPONENT + new sessionStore().returnStateCode() + "&entityKey=" + commonConfig.STATE_ORG_RSD_SR + "&schoolId=" + new sessionStore().returnSchoolID() + "&districtId=" + new sessionStore().returnDistrictID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllSuccess(executedArgument, 'resStateFieldView');
            })
        }
        else if (menuCode === "STAF_ADD" || menuCode === "STAF_LIST") {
            this.props.onCommonResetProps({ name: 'getStfStateFieldView', data: null });
            this.props.onCommonResetProps({ name: 'getStfResStateFieldView', data: null });
            new sessionStore().storeSecureData(commonConfig.SESSION_STAFF_SR_SHOW, 0);
            new sessionStore().storeSecureData(commonConfig.SESSION_STAFF_RES_SR_SHOW, 0);
            new sessionStore().storeSecureData('markersNotificationStatus', commonConfig.IS_HIDE);
            this.setState({ relativeUrl: relativeUrl, redirectStatus: true })
            this.setState({ relativeUrl: relativeUrl }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STATEREPORTING,
                    commonConfig.COMPONENT_SR_DISPLAY_STATE_REPORT_COMPONENT + new sessionStore().returnStateCode() + "&entityKey=" + commonConfig.STATE_STF_INF_SR + "&schoolId=" + new sessionStore().returnSchoolID() + "&districtId=" + new sessionStore().returnDistrictID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllSuccess(executedArgument, 'stfStateFieldView');
                executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STATEREPORTING,
                    commonConfig.COMPONENT_SR_DISPLAY_STATE_REPORT_COMPONENT + new sessionStore().returnStateCode() + "&entityKey=" + commonConfig.STATE_STF_RES_SR + "&schoolId=" + new sessionStore().returnSchoolID() + "&districtId=" + new sessionStore().returnDistrictID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllSuccess(executedArgument, 'stfResStateFieldView');
            })
        }
        else
            this.setState({ relativeUrl: relativeUrl, redirectStatus: true })
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.navbarRedirect && nextState.redirectStatus) {
            this.setState({ redirectStatus: false, relativeUrl: '' });
        }

        if (nextProps.getStateFieldView && Object.keys(nextProps.getStateFieldView).length > 0 && this.state.getStateReportFlag) {
            if (nextProps.getStateFieldView.id > 0) {
                new sessionStore().storeSecureData(commonConfig.SESSION_DISTRICT_SR_SHOW, nextProps.getStateFieldView.id);
            }
            else
                new sessionStore().storeSecureData(commonConfig.SESSION_DISTRICT_SR_SHOW, 0);
            this.setState({ redirectStatus: true, getStateReportFlag: false });
        }
        if (nextProps.getSchStateFieldView && Object.keys(nextProps.getSchStateFieldView).length > 0 && this.state.schStateFieldViewResult) {
            if (nextProps.getSchStateFieldView.id > 0) {
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_SR_SHOW, nextProps.getSchStateFieldView.id);
            }
            else
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_SR_SHOW, 0);
            this.setState({ getStateFieldViewFlag: true, schStateFieldViewResult: false }, () => {
                new sessionStore().storeSecureData('markersNotificationStatus', commonConfig.IS_HIDE);
                this.props.onCommonResetProps({ name: 'getSchStateFieldView', data: null })
            });
        }
        if (nextProps.getResStateFieldView && Object.keys(nextProps.getResStateFieldView).length > 0 && this.state.resStateFieldViewResult) {
            if (nextProps.getResStateFieldView.id > 0) {
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_RES_SR_SHOW, nextProps.getResStateFieldView.id);
            }
            else
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_RES_SR_SHOW, 0);
            this.setState({ getResStateFieldViewFlag: true, resStateFieldViewResult: false }, () => {
                new sessionStore().storeSecureData('markersNotificationStatus', commonConfig.IS_HIDE);
                this.props.onCommonResetProps({ name: 'getResStateFieldView', data: null });
            })
        }
        if (nextProps.getStfStateFieldView && Object.keys(nextProps.getStfStateFieldView).length > 0) {
            if (nextProps.getStfStateFieldView.id > 0) {
                new sessionStore().storeSecureData(commonConfig.SESSION_STAFF_SR_SHOW, nextProps.getStfStateFieldView.id);
            }
            else
                new sessionStore().storeSecureData(commonConfig.SESSION_STAFF_SR_SHOW, 0);
            this.setState({ getStateFieldViewFlag: true }, () => {
                new sessionStore().storeSecureData('markersNotificationStatus', commonConfig.IS_HIDE);
                this.props.onCommonResetProps({ name: 'getStfStateFieldView', data: null })
            });
        }
        if (nextProps.getStfResStateFieldView && Object.keys(nextProps.getStfResStateFieldView).length > 0) {
            if (nextProps.getStfResStateFieldView.id > 0) {
                new sessionStore().storeSecureData(commonConfig.SESSION_STAFF_RES_SR_SHOW, nextProps.getStfResStateFieldView.id);
            }
            else
                new sessionStore().storeSecureData(commonConfig.SESSION_STAFF_RES_SR_SHOW, 0);
            this.setState({ getResStateFieldViewFlag: true }, () => {
                new sessionStore().storeSecureData('markersNotificationStatus', commonConfig.IS_HIDE);
                this.props.onCommonResetProps({ name: 'getStfResStateFieldView', data: null });
            })
        }
        if (this.state.getStateFieldViewFlag && this.state.getResStateFieldViewFlag) {
            this.setState({ redirectStatus: true, getStateFieldViewFlag: false, getResStateFieldViewFlag: false });
        }
    }


    render() {
        if (this.state.redirectStatus && !!this.state.relativeUrl) {
            var currentHost = new sessionStore().returnHostEnvironment();
            if (this.state.relativeUrl.includes(commonConfig.IS_TRANSPORT) === false) {
                if (this.state.relativeUrl.includes(commonConfig.IS_BILLING) === true) {
                    window.location.href = hostArray[currentHost].billing + this.state.relativeUrl + '?' + this.returnSessionItems(commonConfig.IS_BILLING);
                    new sessionStore().removeSessionItem(commonConfig.SESSION_DATE_FORMAT);
                } else if (this.state.relativeUrl.includes(commonConfig.IS_LMS) === true) {
                    window.location.href = hostArray[currentHost].lms + this.state.relativeUrl + '?' + this.returnSessionItems(commonConfig.IS_LMS);
                }
                else {
                    new sessionStore().removeSessionItem(commonConfig.ADMIN_SCHL_PREV_SESSION_PERMISSION);
                    new sessionStore().removeSessionItem(commonConfig.SESSION_DATE_FORMAT);
                    window.location.href = hostArray[currentHost].home + this.state.relativeUrl + '?' + this.returnSessionItems(commonConfig.IS_HOME);
                }                    
            }
            else
                return <Redirect to={this.state.relativeUrl} />
        }
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let checkCurrentUserRoleBySchool = _.find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
        return (
            <React.Fragment>
                {
                    this.props.navBarList && this.props.navBarList.length > 0 && this.props.navBarList.sort(commonFuncObj.compareValues('sortOrder')) && this.props.navBarList.map((menuValue, index) =>
                        menuValue.child.length === 0
                            ?
                            !!menuValue.menuCode && menuValue.isActive === true
                                ?
                                <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, menuValue.relativeUrl, menuValue.menuCode)} className={this.getNavLinkClass(menuValue.activeRoutes, window.location.pathname)}><i className={menuValue.menuClass + " mr-2"}></i> {i18n.t(menuValue.menuCode)}</Link></li >
                                :
                                null
                            :
                            !!menuValue.menuCode && menuValue.isActive === true && menuValue.menuCode !== 'SETT_MAIN'
                                ?
                                //(((!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') && (!!permissionList && permissionList.length > 0 && this.checkLikeArrayValues(permissionList,menuValue.moduleKey))) || (!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole === 'Admin')) &&
                                <li key={index} id={'heading' + menuValue.moduleName.replace(/\s/g, '')}><Link data-toggle="collapse" data-target={'#collapse' + menuValue.moduleName.replace(/\s/g, '')} aria-labelledby={'heading' + menuValue.moduleName.replace(/\s/g, '')} to="#" className={this.getNavLinkClass(menuValue.activeRoutes, window.location.pathname)}><i className={menuValue.menuClass + " mr-2"}></i> {i18n.t(menuValue.menuCode)}</Link>
                                    <ul id={'collapse' + menuValue.moduleName.replace(/\s/g, '')} className={this.collapseClass(menuValue.activeRoutes, window.location.pathname)} aria-labelledby={'heading' + menuValue.moduleName.replace(/\s/g, '')} data-parent="#accordionSidenav">
                                        {
                                            menuValue.child && menuValue.child.length > 0 && menuValue.child.sort(commonFuncObj.compareValues('sortOrder')) && menuValue.child.map((subMenuValue, index) =>
                                                subMenuValue.isActive === true && subMenuValue.isSidebar === 'False'
                                                    ?
                                                    subMenuValue.child && subMenuValue.child.length > 0 && subMenuValue.child.sort(commonFuncObj.compareValues('sortOrder'))
                                                        ?
                                                        subMenuValue.menuCode === 'ATTD_STATT_TCHM' && new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_TEACHER ?
                                                            subMenuValue.child.map((subChildMenuValue, index) =>
                                                                <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, subChildMenuValue.relativeUrl, subChildMenuValue.menuCode)} className={this.activeLinkClass(subChildMenuValue.activeRoutes, window.location.pathname)}>{i18n.t(subChildMenuValue.menuCode)}</Link></li>
                                                            )
                                                            :
                                                            <li key={index}>
                                                                <Link to="#" data-toggle="collapse" className={this.collapseClass(subMenuValue.activeRoutes, window.location.pathname)} data-target={'#collapse' + subMenuValue.title} aria-labelledby={'heading' + subMenuValue.title}>{i18n.t(subMenuValue.menuCode)}</Link>
                                                                <ul id={'collapse' + subMenuValue.title} className={this.collapseClass(subMenuValue.activeRoutes, window.location.pathname)} aria-labelledby={'heading' + subMenuValue.title}
                                                                    data-parent={'#heading' + menuValue.moduleName.replace(/\s/g, '')}>
                                                                    {subMenuValue.child.map((subChildMenuValue, index) =>
                                                                        //(((!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') && (!!permissionList && permissionList.length > 0 && this.checkLikeArrayValues(permissionList,subChildMenuValue.menuCode))) || (!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole === 'Admin')) &&
                                                                        <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, subChildMenuValue.relativeUrl, subChildMenuValue.menuCode)} className={this.activeLinkClass(subChildMenuValue.activeRoutes, window.location.pathname)}>{i18n.t(subChildMenuValue.menuCode)}</Link></li>
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        :
                                                        //(((!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') && (!!permissionList && permissionList.length > 0 && this.checkLikeArrayValues(permissionList,subMenuValue.menuCode))) || (!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole === 'Admin')) &&
                                                        <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, subMenuValue.relativeUrl, subMenuValue.menuCode)} className={this.activeLinkClass(subMenuValue.activeRoutes, window.location.pathname)}>{i18n.t(subMenuValue.menuCode)}</Link></li>
                                                    :
                                                    null
                                            )
                                        }
                                    </ul>
                                </li>
                                :
                                null
                    )}
            </React.Fragment>
        )
    }
    //Return sidebar items dynamically.
    //returnSidebarItems(path) {
    //    var sidebarDetails = sidebarList.map((sidebarValue) =>
    //        sidebarValue.isActive === true ? <li data-position={sidebarValue.sortOrder}><Link to={{ pathname: sidebarValue.menuLink }} className={this.getNavLinkClass(sidebarValue.activeRoutes, path)}>{i18n.t(sidebarValue.menuName)}</Link></li> : null
    //    );
    //    return sidebarDetails;
    //}
}
const mapStateToProps = (state) => {
    return {
        getStateFieldView: state.commonStore.getStateFieldView,
        getSchStateFieldView: state.commonStore.getSchStateFieldView,
        getResStateFieldView: state.commonStore.getResStateFieldView,
        getStfStateFieldView: state.commonStore.getStfStateFieldView,
        getStfResStateFieldView: state.commonStore.getStfResStateFieldView
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetAllSuccess: (executeArgs, title) => dispatch(actionsCommon.callCommonGetByApi(executeArgs, title)),
        onCommonResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersMenuAccess);
