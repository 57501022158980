export class endPointConfig {
    /* Manage Vehicle */
    static COMPONENT_VEHICLE_SAVE = 'v1/transportvehicle/savevehicleinfo';
    static COMPONENT_VEHICLE_UPDATE = 'v1/transportvehicle/updatevehicleinfo';
    static COMPONENT_MANAGE_VEHICLES_GET_ALL = 'v1/transportvehicle/getvehicleinfopagination';
    static COMPONENT_MANAGE_VEHICLES_GET_BYID = 'v1/transportvehicle/getvehicleinfobyid?id=';
    static COMPONENT_VEHICLE_DELETE = 'v1/transportvehicle/deletevehicleinfo';
    static COMPONENT_DELETE_VEHICLE_FILE = 'v1/transportvehicle/deletevehicleimage';
    /* Manage Transport Staff */
    static COMPONENT_MANAGE_TRANSPORT_STAFF_GET_ALL = 'v1/transportstaff/getalltransportstaff'
    static COMPONENT_MANAGE_TRANSPORT_STAFF_GET_BYID = 'v1/transportstaff/gettransportstaffbyid?id='
    static COMPONENT_TRANSPORT_STAFF_SAVE = 'v1/transportstaff/savetransportstaff';
    static COMPONENT_TRANSPORT_STAFF_UPDATE = 'v1/transportstaff/updatetransportstaff';
    static COMPONENT_TRANSPORT_STAFF_DELETE = 'v1/transportstaff/deletetransportstaff';
    static COMPONENT_TRANSPORT_STAFF_EMPLOYMENTINFO_BYID = 'v1/transportstaff/getstaffemploymentinfo?staffId='
    static COMPONENT_TRANSPORT_GET_STAFF_CODE = 'v1/transportstaff/getstaffcode?schoolId='
    static COMPONENT_DELETE_PERSON_IMAGE = 'v1/person/deletephoto';
    /* generate student password */
    static COMPONENT_GENERATE_REGENERATE_STUDENT_PASSWORD = 'v1/studentinfo/generateselectivestudentusername';
    static COMPONENT_GET_PENDING_PASSWORD_STUDENT_LIST = 'v1/studentinfo/getpendingpasswordstudentlist?schoolId=';
    /* Bus Stop */
    static COMPONENT_STOP_SAVE = 'v1/transportbusstop/savebusstop';
    static COMPONENT_STOP_UPDATE = 'v1/transportbusstop/updatebusstop';
    static COMPONENT_MANAGE_STOPS_GET_ALL = 'v1/transportbusstop/getallbusstopbyschoolpagination';
    static COMPONENT_MANAGE_STOP_GET_BYID = 'v1/transportbusstop/getbusstopbyid?id=';
    static COMPONENT_STOP_DELETE = 'v1/transportbusstop/deletebusstop';
    static COMPONENT_STOP_GET_BYSCHOOLID = 'v1/transportbusstop/getbusstopbyschoolid?schoolId=';
    /* Manage Routes */
    static COMPONENT_GET_ALL_ROUTE = 'v1/transportbusroute/getpaginationbusrouteinfo';
    static COMPONENT_ROUTE_SAVE = 'v1/transportbusroute/savebusroute';
    static COMPONENT_ROUTE_UPDATE = 'v1/transportbusroute/updatebusroute';
    static COMPONENT_GET_ROUTE_BYID = 'v1/transportbusroute/getbusroutebyid?id=';
    static COMPONENT_ROUTE_DELETE = 'v1/transportbusroute/deletebusroute';
    /* Manage Route Detail */
    static COMPONENT_ROUTE_DETAIL_SAVE = 'v1/transportroutedetail/saveroutedetail';
    static COMPONENT_ROUTE_DETAIL_UPDATE = 'v1/transportroutedetail/updateroutedetail';
    static COMPONENT_MANAGE_ROUTE_DETAIL_GET_ALL = 'v1/transportroutedetail/getallroutedetailpagination';
    static COMPONENT_MANAGE_ROUTE_DETAIL_GET_BYID = 'v1/transportroutedetail/getroutedetailbyid?id=';
    static COMPONENT_ROUTE_DETAIL_DELETE = 'v1/transportroutedetail/deleteroutedetail';
    /* Manage Staff Route Detail */
    static COMPONENT_STAFF_ROUTE_DETAIL_SAVE = 'v1/transportstaffroute/savestaffroute';
    static COMPONENT_STAFF_ROUTE_DETAIL_UPDATE = 'v1/transportstaffroute/updatestaffroute';
    static COMPONENT_STAFF_ADDED_ROUTE_DETAIL_GET_ALL = 'v1/transportstaffroute/gettransportstaffroutepagination';
    static COMPONENT_STAFF_ROUTE_DETAIL_GET_ALL = 'v1/transportstaffroute/getstaffroutelistbydepartment?schoolId=';
    static COMPONENT_STAFF_ROUTE_DETAIL_GET_BYID = 'v1/transportstaffroute/getstaffroutebyid?id=';
    static COMPONENT_STAFF_ROUTE_DETAIL_DELETE = 'v1/transportstaffroute/deletetstaffroute';
    static COMPONENT_STAFF_ROUTE_GET_AVAILABLE_SEAT = 'v1/transportstaffroute/getavailableseat?vehicleRoute=';
    static COMPONENT_GET_ALL_TRANSPORT_STOPPAGE_ROUTE = 'v1/transportstaffroute/getstoppagesbyvehiclerouteid?vehicleRoute=';
    /* Common person */
    static COMPONENT_SCHEDULE_TEACHER_GETALL = 'v1/staffinfo/getallteacherfromstaffbyschool?schoolId=';
    static COMPONENT_STAFF_GET_STAFF_HOLIDAY_LIST = 'v1/staffinfo/getallstaffforholidaylist?schoolId=';
    /* Transport Request */
    static COMPONENT_TRANSPORT_REQUEST_GET_ALL = 'v1/transportrequest/gettransportrequestpagination';
    static COMPONENT_TRANSPORT_GET_REQUEST_BY_ID = 'v1/transportrequest/gettransportrequestbyid?id=';
    static COMPONENT_TRANSPORT_REQUEST_GET_STUDENT_BY_FILTER = 'v1/transportrequest/getstudentbyfilter?schoolId=';
    static COMPONENT_TRANSPORT_SAVE_REQUEST = 'v1/transportrequest/savetransportrequest';
    static COMPONENT_TRANSPORT_APPROVE_DECLINE_REQUEST = 'v1/transportrequest/approvedeclinerequest';
    static COMPONENT_TRANSPORT_GET_REQUEST_BY_STUDENT_ID = 'v1/transportrequest/gettransportrequestbystudentid?studentId=';
    static COMPONENT_TRANSPORT_UPDATE_REQUEST = 'v1/transportrequest/updatetransportrequest';
    static COMPONENT_TRANSPORT_REQUEST_DELETE = 'v1/transportrequest/deletetransportrequest';
    /* Request Pickup Drop */
    static COMPONENT_GET_PICKUP_DROP_REQUEST_BY_STUDENT_ID = 'v1/guardianpickdroprequest/gettransportrequestbystudentid?studentId=';
    static COMPONENT_PICKUP_DROP_REQUEST_SAVE = 'v1/guardianpickdroprequest/savetransportrequest';
    static COMPONENT_PICKUP_DROP_REQUEST_UPDATE = 'v1/guardianpickdroprequest/updatetransportrequest';
    static COMPONENT_GET_PICKUP_DROP_REQUEST_BY_ID = 'v1/guardianpickdroprequest/gettransportrequestbyid?id=';
    static COMPONENT_GET_CURRENT_PICKUP_DROP_BY_STUDENT_ID = 'v1/guardianpickdroprequest/getcurrentpickupdropbystudentid?studentId=';
    static COMPONENT_PICKUP_DROP_REQUEST_DELETE = 'v1/guardianpickdroprequest/deletetransportrequest';
    /* Request Pickup Drop Approval */
    static COMPONENT_GET_APPROVE_DECLINE_REQUEST_BY_GRADE_ID = 'v1/adminapprovedeclinerequest/getapprovedeclinerequestbygradeid?';
    static COMPONENT_GET_APPROVE_DECLINE_REQUEST_BY_ID = 'v1/adminapprovedeclinerequest/getstudentapprovedeclinebyid?id=';
    static COMPONENT_UPDATE_APPROVE_DECLINE_REQUEST = 'v1/adminapprovedeclinerequest/updateapprovedeclinerequest';
    /* Manage Velicle Log */
    static COMPONENT_GET_DRIVER_CONDUCTOR_DETAILS = 'v1/transportvehiclelog/getprimarydriverconductordeatils?';
    static COMPONENT_GET_ALL_VEHICLE_LOG = 'v1/transportvehiclelog/getvehiclelogbyschoolid?';
    static COMPONENT_VEHICLE_LOG_SAVE = 'v1/transportvehiclelog/savevehicleloginfo';
    static COMPONENT_VEHICLE_LOG_UPDATE = 'v1/transportvehiclelog/updatevehicleloginfo';
    static COMPONENT_GET_VEHICLE_LOG_BYID = 'v1/transportvehiclelog/getvehicleloginfobyid?id=';
    static COMPONENT_GET_VEHICLE_LOG_BY_ROUTEID = 'v1/transportvehiclelog/getvehiclelogbyvechiclerouteid?';
    static COMPONENT_GET_VEHICLE_LOG_VIEW = 'v1/transportvehiclelog/viewvehicleloginfobyid?id=';
    static COMPONENT_GET_VEHICLE_LOG_DELETE = 'v1/transportvehiclelog/deletevehicleloginfo';
    static COMPONENT_INCIDENT_UPDATE = 'v1/transportvehiclelog/updatevehiclelogincidentinfo';
    static COMPONENT_INCIDENT_SAVE = 'v1/transportvehiclelog/savevehiclelogincidentinfo';
    static COMPONENT_VEHICLE_LOG_DELETE_IMAGE = 'v1/transportvehiclelog/deletephoto';
    /* Manage Vehicle Route */
    static COMPONENT_GET_ALL_TRANSPORT_ROUTE = 'v1/transportvehicleroute/getallvehicleroute?sessionId=';
    static COMPONENT_GET_VEHICLE_ROUTE_BYID = 'v1/transportvehicleroute/getvehicleroutebyid?id=';
    static COMPONENT_VEHICLE_ROUTE_SAVE = 'v1/transportvehicleroute/savevehicleroutedetail';
    static COMPONENT_VEHICLE_ROUTE_UPDATE = 'v1/transportvehicleroute/updatevehicleroutedetail';
    static COMPONENT_VEHICLE_ROUTE_DELETE = 'v1/transportvehicleroute/deletevehicleroute?id='
    /* Manage Student Route */
    static COMPONENT_MANAGE_STUDENT_ROUTE_GET_ALL = 'v1/transportstudentroute/gettransportstudentroutepagination';
    static COMPONENT_GET_STUDENT_ROUTE_BYID = 'v1/transportstudentroute/getstudentroutebyid?id='
    static COMPONENT_STUDENT_ROUTE_DELETE = 'v1/transportstudentroute/deletetstudentroute'
    static COMPONENT_GET_STUDENT_BY_FILTER = 'v1/transportstudentroute/getstudentbyfilter'
    static COMPONENT_STUDENT_ROUTE_SAVE = 'v1/transportstudentroute/savestudentroute'
    static COMPONENT_STUDENT_ROUTE_UPDATE = 'v1/transportstudentroute/updatestudentroute'
    /* Take Attendance */
    static COMPONENT_GET_VEHICLE_LOG = 'v1/takeattendance/getvehicleloginfobyvechicleroute?';
    static COMPONENT_GET_ATTENDANCE_STUDENT_LIST = 'v1/takeattendance/getstudentlistattendance?';
    static COMPONENT_TAKE_ATTENDANCE = 'v1/takeattendance/takestudentattendance?';
    static COMPONENT_GET_STUDENT_COUNT = 'v1/takeattendance/getscheduledstudentcount?';
    /* Custom Notification */
    static COMPONENT_MANAGE_CUSTOM_NOTIFICATION_PAGINATION_GET_ALL = 'v1/customnotification/getcustomnotificationpagination';
    static COMPONENT_GET_CUSTOM_NOTIFICATION_BYID = 'v1/customnotification/getcustomnotificationbyid?id=';
    static COMPONENT_CUSTOM_NOTIFICATION_SAVE = 'v1/customnotification/savecustomnotification'
    static COMPONENT_CUSTOM_NOTIFICATION_UPDATE = 'v1/customnotification/updatecustomnotification'
    static COMPONENT_CUSTOM_NOTIFICATION_DELETE = 'v1/customnotification/deletecustomnotification?id='
    // Upload Maximum File Size
    static COMPONENT_UPLOAD_FILE_SIZE_GET_BYID = 'v1/uploadfilesize/getbydistrictid?DistrictId=';
    static COMPONENT_DISTRICT_GET_GENERAL_INFO = 'v1/districtgeneralinfo/getbydistrictid?Id=';
}
export default endPointConfig;