import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import * as actionsCommon from '../../shared/store/actions/index';
import MarkersDelete from './markersDeleteComponent';
import i18n from '../../../i18n'
import ExecuteArgs from '../../shared/model/executeArgs';
import commonConfig from '../commonConfig';
import Validation from './validationComponent';
import sessionStore from './sessionStoreComponent';
import MarkersCommonSelect from './markersCommonSelect';
import _ from 'lodash';
//import defaultImage from './../../../assets/images/no_image_user.svg';
import MarkersFileUpload from './markersFileUpload';
import endPointConfig from '../commonEndPointConfig';
import MarkersDatePicker from './markersDatepicker';
class MarkersCommonPersonEdit extends React.Component {
    initializeFields = {
        firstName: '',
        middleName: '',
        lastName: '',
        nameSuffix: '',
        lookupNameSuffix: null,
        homePhoneNumber: '',
        workPhoneNumber: '',
        mobileNumber: '',
        primaryEmail: '',
        alternateEmail: '',
        gender: '',
        profilePicId: 1,
        lookupSalutation: 0,
        listAddress: [],
        files: null,
        personId: null,
        listCredential: [],
        lookupOccupationType: null,
        occupationTitle: '',
        designation: '',
        monthlyIncome: null,
        workEmail: '',
        gradeId: null,
        studentId: null,
        selectedFileList: [],
        dateOfBirth: ''
    }
    initializeAddressFields = {
        lookupAddressType: null,
        addressLine: "",
        city: "",
        state: "",
        county: "",
        countryCode: "",
        zip: "",
        streetNoName: "",
        aprtSuiteRoomNo: "",
        buildingSiteNo: "",
        geoLocation: null,
        isDefault: true,
        isActive: true
    }
    initializeCredentialFields = {
        credentialName: '',
        lookupCredentialType: null,
        issuranceDate: '',
        expirationDate: '',
        issuingState: '',
        certificateOrLicenseNo: '',
        issuingAuthority: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            fields: { ...this.initializeFields },
            newAddress: { ...this.initializeAddressFields },
            newCredential: { ...this.initializeCredentialFields },
            genderTypeList: [
                { "id": "M", "title": "Male" },
                { "id": "F", "title": "Female" },
                { "id": "O", "title": "Other" }
            ],
            phoneTypeList: [
                { "id": 901, "title": "Work Phone" },
                { "id": 902, "title": "Mobile Phone" },
                { "id": 903, "title": "Home Phone" }
            ],
            requiredFieldsPhone: [
                { fieldName: 'tempPhoneNumber', fieldType: 'regExp', regExp: commonConfig.PHONE_NUMBER_ADD_PERSON, minLength: commonConfig.PHONE_NUMBER_MIN_LENGTH },
                { fieldName: 'tempPhoneType', fieldType: 'text' },
            ],
            requiredFieldsAddress: [
                { fieldName: 'lookupAddressType', fieldType: 'default' },
                { fieldName: 'addressLine', fieldType: 'regExp', regExp: commonConfig.ADDRESS_SCHOOL },
                { fieldName: 'city', fieldType: 'regExp', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY },
                { fieldName: 'state', fieldType: 'regExp', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY },
                { fieldName: 'county', fieldType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_COUNTY_REGEX },
                { fieldName: 'countryCode', fieldType: 'default' },
                { fieldName: 'zip', fieldType: 'regExp', regExp: commonConfig.POSTAL_CODE_ZIP }
            ],
            requiredFields: [
                { fieldName: 'firstName', fieldType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX },
                { fieldName: 'lastName', fieldType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX },
                { fieldName: 'middleName', fieldType: 'regExpNotRequired', regExp: commonConfig.SCHOOL_NAME_REGEX },
                { fieldName: 'primaryEmail', fieldType: 'regExp', regExp: commonConfig.EMAIL },
                { fieldName: 'alternateEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL },
                { fieldName: 'selectedFileList', fieldType: 'onlyFileType', fileType: commonConfig.FILE_TYPE_IMAGE, fileMaxSize: new sessionStore().returnMaxFileSize(), fileMinSize: new sessionStore().returnMinFileSize() },
                { fieldName: 'occupationTitle', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'designation', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'monthlyIncome', fieldType: 'regExpNotRequired', regExp: commonConfig.FLOAT_VAL },
                { fieldName: 'workEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL }
            ],
            requiredFieldsCredential: [
                { fieldName: 'credentialName', fieldType: 'regExp', regExp: commonConfig.INSTITUITION_NAME },
                { fieldName: 'issuingState', fieldType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY },
                { fieldName: 'certificateOrLicenseNo', fieldType: 'regExpNotRequired', regExp: commonConfig.LICENCE_NO },
                { fieldName: 'issuingAuthority', fieldType: 'regExpNotRequired', regExp: commonConfig.CERTIFICATE_NUMBER },
                { fieldName: 'expirationDate', fieldType: 'dateCompNotRequired', associatedField: 'issuranceDate' },
                { fieldName: 'lookupCredentialType', fieldType: 'default' },
                { fieldName: 'issuranceDate', fieldType: 'default' },
            ],
            integerFieldAddress: ['lookupAddressType'],
            integerFieldCredential: ['lookupCredentialType'],
            integerField: ["monthlyIncome", "lookupOccupationType"],
            lookupSalutationList: [],
            lookupAddressTypeList: [],
            isError: 0,
            tempPhoneType: '',
            tempPhoneNumber: '',
            tempPhnArr: [],
            showAddNotify: false,
            isErrorCredential: 0,
            lookupCredentialType: [],
            isErrorPhone: 0,
            form_data: {},
            studentId: 0,
            isEdit: 0,
            getPersonInfoFlag: false,
            editAddress: false,
            fileDetails: [],
            key: null,
            deleteType: null,
            clearFile: false,
            isOccupationFieldsNotEmpty: false
        }
        this.props.onCommonResetProps({ name: 'stateByCountryList', data: [] });
        this.props.onCommonResetProps({ name: 'countyByStateList', data: [] });
        this.props.onGetLookup(commonConfig.LOOKUP_STAFF_CREDENTIAL_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_SALUTATION_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_ADDRESS_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_PERSON_OCCUPATION_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_NAME_SUFFIX_TYPE);
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_RESOURCE_GET_COUNTRY,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetCountryList(executedArgument);
    }
    componentDidMount = () => {
        window.$('#editPersonModal').modal({
            backdrop: 'static',
            keyboard: false,
            show: false
        });
        window.$("#editPersonModal").on('hidden.bs.modal', () => {
            this.setState({ fields: { ...this.initializeFields }, editId: null, clearFile: true, tempPhnArr: [], tempPhoneNumber: '', tempPhoneType: '', isOccupationFieldsNotEmpty: false }, () => {
                let removeOccupationRequiredFields = this.state.requiredFields.filter(requiredField => requiredField.fieldName === "occupationTitle" ? requiredField.fieldType !== "regExp" : requiredField.fieldName !== 'lookupOccupationType');
                document.getElementById("addschooldesig").reset();
                this.setState({ requiredFields: removeOccupationRequiredFields });
            });
        });
    }
    setFileField = (selectedFileList) => {
        let fields = Object.assign({}, this.state.fields);
        fields["selectedFileList"] = selectedFileList;
        this.setState({ fields: fields });
    }

    getPersonInfo = () => {
        this.props.onCommonResetProps({ name: 'getPerson', data: {} });
        const executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_PERSON_GETBYID + this.state.editId, commonConfig.METHOD_TYPE_GET);
        this.props.onGetById(executedArgument);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.editId && nextProps.editId !== this.state.editId) {
            this.setState({ editId: nextProps.editId, isError: 0, isEdit: 1 }, () => {
                this.getPersonInfo()
            })
        }
        if (nextProps.lookupCredentialTypeList && nextProps.lookupCredentialTypeList.length > 0) {
            this.setState({
                lookupCredentialType: nextProps.lookupCredentialTypeList
            })
        }
        if (this.state.isEdit === 1) {
            if (nextProps.getPerson && Object.keys(nextProps.getPerson).length > 0) {
                this.setState({ fields: nextProps.getPerson, isEdit: 0 }, () => {
                    const phoneArray = [];
                    if (nextProps.getPerson.homePhoneNumber !== null) {
                        const homePhNoStr = nextProps.getPerson.homePhoneNumber.split(",");
                        homePhNoStr.forEach((data, i) => {
                            const homePhNoObj = {};
                            if (data !== "") {
                                homePhNoObj["phoneType"] = "Home Phone";
                                homePhNoObj["phoneNumber"] = data;
                                phoneArray.push(homePhNoObj);
                            }
                        })
                    }
                    if (nextProps.getPerson.workPhoneNumber !== null) {
                        const workPhNoStr = nextProps.getPerson.workPhoneNumber.split(",");
                        workPhNoStr.forEach((data, i) => {
                            const workPhNoObj = {};
                            if (data !== "") {
                                workPhNoObj["phoneType"] = "Work Phone";
                                workPhNoObj["phoneNumber"] = data;
                                phoneArray.push(workPhNoObj);
                            }
                        })
                    }
                    if (nextProps.getPerson.mobileNumbers !== null) {
                        const mobilePhNoStr = nextProps.getPerson.mobileNumber.split(",");
                        mobilePhNoStr.forEach((data, i) => {
                            const mobilePhNoObj = {};
                            if (data !== "") {
                                mobilePhNoObj["phoneType"] = "Mobile Phone";
                                mobilePhNoObj["phoneNumber"] = data;
                                phoneArray.push(mobilePhNoObj);
                            }
                        })
                    }

                    let fileDetails = [];
                    let fileIdObjNew = {};
                    if (nextProps.getPerson.profilePicId !== null) {
                        fileIdObjNew['id'] = nextProps.getPerson.profilePicId;
                        fileIdObjNew['name'] = nextProps.getPerson.fileName;
                        fileIdObjNew['thumbnailImage'] = nextProps.getPerson.thumbnail;
                        fileDetails.push(fileIdObjNew);
                    }

                    if (nextProps.getPerson.dateOfBirth !== null) {
                        var fields = this.state.fields;
                        var dob = nextProps.getPerson.dateOfBirth.split("T");
                        fields['dateOfBirth'] = dob[0];
                    }
                    this.setState({
                        fields: { ...fields, ...this.state.fields }, fileDetails: fileDetails, tempPhnArr: phoneArray
                    }, () => {
                    })
                    window.$('#editPersonModal').modal('show');
                })
            }
        }
        if (nextProps.lookupAddressTypeList) {
            this.setState({ lookupAddressTypeList: nextProps.lookupAddressTypeList })
        }
        if (nextProps.lookupSalutationList) {
            this.setState({ lookupSalutationList: nextProps.lookupSalutationList })
        }
        if (nextProps.saveSchoolPerson && JSON.stringify(nextProps.saveSchoolPerson.Id) && this.state.getPersonInfoFlag) {
            this.setState({ getPersonInfoFlag: false, editId: null, fields: this.initializeFields, tempPhoneNumber: '', tempPhoneType: '' }, () => {
                window.$("#addschooldesig").trigger("reset");
                window.$('#editPersonModal').modal('hide');
                this.props.handleForm(null, true);
            });
        }
    }
    phoneError = (isErrorPhone) => {
        var result = false
        const { tempPhoneNumber, tempPhoneType } = this.state;
        if (isErrorPhone === 1) {
            result = true;
        } else if ((tempPhoneType === '' && !!tempPhoneNumber) || (tempPhoneNumber === '' && !!tempPhoneType)) {
            this.setState({ isErrorPhone: 1 });
            result = true;
        } else {
            result = false
        }
        return result
    }
    //This method checks whether any data is populated in any of the occupation sub-component fields and push requiredFieldsOfOccupation if data is populated
    updateOccupationRequiredFields = (eventName) => {
        let occupationFieldArray = ['workEmail', 'occupationTitle', 'lookupOccupationType', 'designation', 'monthlyIncome'];
        if (occupationFieldArray.indexOf(eventName) !== -1) {
            let occupationFields = _.pick(this.state.fields, occupationFieldArray);
            let isOccupationFieldsNotEmpty = Object.values(occupationFields).some(occupationField => (occupationField !== null && occupationField !== ''));
            let requiredFieldsOfOccupation = [{ fieldName: 'lookupOccupationType', fieldType: 'default' }, { fieldName: 'occupationTitle', fieldType: 'regExp', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }]
            let removeOccupationRequiredFields = (requiredFields) => requiredFields.filter(requiredField => requiredField.fieldName === "occupationTitle" ? requiredField.fieldType !== "regExp" : requiredField.fieldName !== 'lookupOccupationType');
            let requiredFields = !!isOccupationFieldsNotEmpty ? [...this.state.requiredFields, ...requiredFieldsOfOccupation] : removeOccupationRequiredFields(this.state.requiredFields);
            this.setState({ requiredFields, isOccupationFieldsNotEmpty }, () => {
            });
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields);
        if (submitStatus === 0 || this.phoneError(this.state.isErrorPhone)) {
            this.setState({ isError: 1 });
        } else {
            this.setState({ isError: 0 }, () => {
                let fields = this.state.fields;
                this.setState({ getPersonInfoFlag: true, fields: fields }, () => {
                    let phFields = this.state.fields;
                    this.setState({ fields: phFields }, () => {
                    });
                    this.setState({ showAddNotify: false }, () => {
                        const fileData = new FormData()
                        fileData.append('inputData', JSON.stringify(this.state.fields));
                        fileData.append('component', commonConfig.COMPONENT_RESOURCE);
                        fileData.append('endpoint', commonConfig.COMPONENT_PERSON_UPDATE);
                        if (this.state.fields.selectedFileList) {
                            for (let file of this.state.fields.selectedFileList) {
                                fileData.append("postedFile", file);
                            }
                        }
                        this.props.onUpdatePersonSucess(fileData);
                    });
                });
            });
        }
    }
    closeModal = () => {
        this.props.handleForm(null, false)
        window.$('#editPersonModal').modal('hide');
    }
    handleChange = event => {
        let fields = Object.assign({}, this.state.fields);
        let integerField = this.state.integerField;
        let eventName = event.target.name;
        if (integerField.indexOf(event.target.name) === -1) {
            fields[event.target.name] = event.target.value;
        } else {
            if (isNaN(parseFloat(event.target.value))) {
                fields[event.target.name] = null;
            } else {
                fields[event.target.name] = parseInt(event.target.value, 10);
            }
        }
        this.setState({ fields: fields }, () => {
            this.updateOccupationRequiredFields(eventName);
        });
    }
    handleChangePhone = event => {
        this.setState({ tempPhoneNumber: event.target.value });
    }
    handleChangePhoneType = event => {
        this.setState({ tempPhoneType: event.target.value });
    }
    handleChangeAddress = event => {
        let newAddress = this.state.newAddress;
        if (event.target.name === 'countryCode') {
            newAddress['state'] = '';
            newAddress['county'] = '';
            this.props.onCommonResetProps({ name: 'countyByStateList', data: [] });
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY + event.target.value,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetStateByCountry(executedArgument);
        }
        if (event.target.name === 'state' && !!newAddress.countryCode && newAddress.countryCode === 'US') {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_COUNTY_BY_STATE + event.target.value,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetCountyByState(executedArgument);
        }
        if (event.target.name === "isDefault") {
            newAddress[event.target.name] = event.target.checked;
        } else {
            if (this.state.integerFieldAddress.indexOf(event.target.name) === -1) {
                newAddress[event.target.name] = event.target.value;
            }
            else {
                newAddress[event.target.name] = parseInt(event.target.value, 10);
            }
        }
        this.setState({ newAddress: newAddress });
    }
    handleChangeCredential = event => {
        let newCredential = this.state.newCredential;
        if (this.state.integerFieldCredential.indexOf(event.target.name) === -1) {
            newCredential[event.target.name] = event.target.value;
        }
        else {
            newCredential[event.target.name] = parseInt(event.target.value, 10);
        }
        this.setState({ newCredential: newCredential });
    }
    //This function is called for delete address from temporary array.
    removeAddress = (personAddressId, key) => {
        this.setState({ deleteType: 'ADDRESS', key }, () => {
            this.deleteAddressCredential(personAddressId)
        });
    }
    removeCredential = (personCredentialId, key) => {
        this.setState({ deleteType: 'CREDENTIAL', key }, () => {
            this.deleteAddressCredential(personCredentialId)
        });
    }
    editAddress = (item, addressIndex) => {
        let newAddress = { ...item }
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY + newAddress.countryCode,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetStateByCountry(executedArgument);
        if (!!newAddress.countryCode && newAddress.countryCode === 'US') {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_COUNTY_BY_STATE + newAddress.state,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetCountyByState(executedArgument);
        }
        this.setState({ newAddress, editAddress: true, addressIndex }, () => {
            window.$("#addNewAddress").show();
            window.$("#btnAddModal").hide();
        })
    }
    addressShow = () => {
        var address = null;
        this.state.fields.listAddress && this.state.fields.listAddress.length > 0 ?
            address = this.state.fields.listAddress.map((item, key) =>
                <div className="panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-10 col-md-10 col-lg-10">
                                <div className="address">
                                    <label className="d-block d-md-none f-s-12 text-muted m-b-5">{i18n.t('address')}</label>
                                    <h4><Link to="#" data-toggle="tooltip" data-placement="top" title={i18n.t('defaultAddress')}>{item.lookupAddressType === 15901 ? <i className="icon-star-full2 mr-2"></i> : ''}</Link>
                                        {
                                            item.lookupAddressTypeTitle === undefined ?
                                                item.lookupAddressType === 15901 ? "Primary Address" : item.lookupAddressType === 15902 ? "Office Address" :
                                                    item.lookupAddressType === 15903 ? "Mailing Address" : item.lookupAddressType === 15904 ? "Physical Address" :
                                                        item.lookupAddressType === 15905 ? "Shipping Address" : item.lookupAddressType === 15906 ? "Billing Address" :
                                                            "Other Address" : item.lookupAddressTypeTitle
                                        }
                                    </h4>
                                    <p>{item.addressLine}, {item.streetNoName}, {item.aprtSuiteRoomNo}, {item.buildingSiteNo}, {item.city},{item.state},{item.zip}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <div className="action-icons text-right">
                                    <Link to="#" onClick={() => this.editAddress(item, key)}><i className="icon-pen6"></i></Link>
                                    <Link to="#" onClick={(e) => this.removeAddress(item.personAddressId, key)}><i className="icon-bin2 "></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : address = <div className="panel-default">
                <div className="panel-body">
                    <div className="text-danger f-s-12 text-center" role="alert">
                        <i className="icon-info2 m-r-5"></i> {i18n.t('noAddressFound')}
                    </div>
                </div>
            </div>;
        return address;
    }
    credentialShow = () => {
        var credential = null;
        !!this.state.fields.listCredential && this.state.fields.listCredential.length > 0 ?
            credential = this.state.fields.listCredential.map((data, i) =>
                <div className="panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-10 col-md-7 col-lg-10">
                                <div className="address">
                                    <label className="d-block d-md-none f-s-12 text-muted m-b-5">{i18n.t('address')}</label>
                                    <h4>{data.credentialName}</h4>
                                    <p>{data.issuingState},{data.certificateOrLicenseNo},{data.issuingAuthority}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <div className="action-icons text-right">
                                    <Link to="#" onClick={() => this.editCredential(data, i)}><i className="icon-pen6"></i></Link>
                                    <Link to="#" onClick={(e) => this.removeCredential(data.id, i)}><i className="icon-bin2 "></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : credential = <div className="panel-default">
                <div className="panel-body">
                    <div className="text-danger f-s-12 text-center" role="alert">
                        <i className="icon-info2 m-r-5"></i> {i18n.t('noCredentialFound')}
                    </div>
                </div>
            </div>;
        return credential;
    }
    editCredential = (item, credentialIndex) => {
        let newCredential = { ...item }
        this.setState({ newCredential, editCredential: true, credentialIndex }, () => {
            window.$("#addNewCredential").show();
            window.$("#btnAddCredential").hide();
        })
    }
    phoneShow = () => {
        var phoneDetails = null;
        if (this.state.tempPhnArr.length > 0) {
            phoneDetails = this.state.tempPhnArr.map((arrVal, key) =>
                <div className="row mainClass">
                    <div className="col-md-4 col-lg-4">
                        <div className="form-group">
                            <select className="custom-select" onChange={this.handleChangePhoneType} value={arrVal.phoneType}>
                                <option value="">{arrVal.phoneType}</option>
                                {this.state.phoneTypeList.map((lookup, key) => {
                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-6">
                        <div className="form-group">
                            <input type="text" maxLength={10} className="form-control" placeholder={i18n.t('enterPhone')} name="homePhoneNumber" onChange={this.handleChangePhone} value={arrVal.phoneNumber} />
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-2">
                        <div className="form-group">
                            <div className="text-center appendMinus">
                                <Link to="#" class="btn btn-outline-danger btn-block mr-2 minus" onClick={(e) => this.deletePhone(e, arrVal.phoneNumber)}><i class="icon-minus3"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return phoneDetails;
    }
    deletePhone = (e, val) => {
        e.preventDefault();
        var myArray = this.state.tempPhnArr;
        myArray = $.grep(myArray, function (element, index) { return element.phoneNumber === val }, true);
        this.setState({ tempPhnArr: myArray }, () => {
            const homePhoneNums = [];
            const mobilePhoneNums = [];
            const workPhoneNums = [];
            this.state.tempPhnArr.forEach((data, key) => {
                if (data.phoneType === commonConfig.IS_HOME_PHONE) {
                    homePhoneNums.push(data.phoneNumber)
                } else if (data.phoneType === commonConfig.IS_WORK_PHONE) {
                    workPhoneNums.push(data.phoneNumber)
                } else if (data.phoneType === commonConfig.IS_MOBILE_PHONE) {
                    mobilePhoneNums.push(data.phoneNumber)
                }
            });
            let fields = this.state.fields;
            fields['homePhoneNumber'] = homePhoneNums.join(",");
            fields['workPhoneNumber'] = workPhoneNums.join(",");
            fields['mobileNumber'] = mobilePhoneNums.join(",");
            this.setState({ fields: fields });
        })
    }
    //This function is called for add phone field.
    fieldShowMore = event => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state, this.state.requiredFieldsPhone);
        if (submitStatus === 0) {
            this.setState({ isErrorPhone: 1 })
        } else if (this.state.tempPhoneType !== "" && this.state.tempPhoneNumber !== "" && submitStatus === 1) {
            let tempPhoneArray = this.state.tempPhnArr;
            tempPhoneArray.push({ phoneType: this.state.tempPhoneType, phoneNumber: this.state.tempPhoneNumber })
            this.setState({ tempPhnArr: tempPhoneArray, isErrorPhone: 0 }, () => {
                this.setState({ tempPhoneType: '' });
                this.setState({ tempPhoneNumber: '' });
                this.phoneShow();
                let fields = this.state.fields;
                if (this.state.tempPhoneType === commonConfig.IS_HOME_PHONE) {
                    if (fields['homePhoneNumber'] !== '') {
                        fields['homePhoneNumber'] = fields['homePhoneNumber'] + ',' + this.state.tempPhoneNumber;
                    } else {
                        fields['homePhoneNumber'] = this.state.tempPhoneNumber
                    }
                }
                if (this.state.tempPhoneType === commonConfig.IS_WORK_PHONE) {
                    if (fields['workPhoneNumber'] !== '') {
                        fields['workPhoneNumber'] = fields['workPhoneNumber'] + ',' + this.state.tempPhoneNumber;
                    } else {
                        fields['workPhoneNumber'] = this.state.tempPhoneNumber
                    }
                }
                if (this.state.tempPhoneType === commonConfig.IS_MOBILE_PHONE) {
                    if (fields['mobileNumber'] !== '') {
                        fields['mobileNumber'] = fields['mobileNumber'] + ',' + this.state.tempPhoneNumber;
                    } else {
                        fields['mobileNumber'] = this.state.tempPhoneNumber
                    }
                }
                this.setState({ fields: fields }, () => {
                });
            });
        }
    }
    saveAddress = () => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.newAddress, this.state.requiredFieldsAddress);
        if (submitStatus === 0) {
            this.setState({ isError: 1 });
        } else {
            this.setState({ isError: 0 }, () => {
                let fields = Object.assign({}, this.state.fields);
                if (this.state.editAddress) {
                    var address = { ...this.state.newAddress }
                    var key = this.state.addressIndex
                    fields['listAddress'].splice(key, 1, address)
                    this.setState({ fields, editAddress: false, addressIndex: null }, () => {
                        this.clearAddressFields()
                    })
                } else {
                    if (this.state.newAddress.length !== '' || this.state.newAddress.length !== 'undefined' || this.state.newAddress.length !== null) {
                        fields['listAddress'].push(this.state.newAddress);
                    }
                    this.setState({ fields: fields }, () => {
                        this.clearAddressFields()
                    });
                    //this.setState({ newAddress: this.initializeAddressFields });
                }
                window.$("#addNewAddress").hide();
                window.$("#btnAddModal").show();
            })
        }
    }
    clearAddressFields = () => {
        this.setState({
            ...this.state, isAddressError: 0, addressIndex: null, editAddress: false,
            newAddress: {
                lookupAddressType: null,
                addressLine: '',
                city: "",
                state: "",
                county: "",
                countryCode: "",
                zip: "",
                geoLocation: '',
                isDefault: true
            }
        }, () => {
            window.$("#addNewAddress").hide();
            window.$("#btnAddModal").show();
        })
    }
    saveCredential = () => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.newCredential, this.state.requiredFieldsCredential);
        if (submitStatus === 0) {
            this.setState({ isErrorCredential: 1 });
        } else {
            this.setState({ isErrorCredential: 0 }, () => {
                let fields = Object.assign({}, this.state.fields);
                if (this.state.editCredential) {
                    var credential = { ...this.state.newCredential }
                    var key = this.state.credentialIndex
                    fields['listCredential'].splice(key, 1, credential)
                    this.setState({ fields, editCredential: false, credentialIndex: null }, () => {
                        this.clearCredentialFields()
                    })
                }
                else {
                    if (this.state.newCredential.length !== '' || this.state.newCredential.length !== 'undefined' || this.state.newCredential.length !== null) {
                        let cred = this.state.newCredential;
                        cred['personId'] = this.state.editId;
                        fields['listCredential'].push(cred);
                    }
                    this.setState({ fields, editCredential: false, credentialIndex: null }, () => {
                        this.clearCredentialFields()
                    })
                }
                window.$("#addNewCredential").hide();
                window.$("#btnAddCredential").show();
            })
        }
    }
    clearCredentialFields = () => {
        this.setState({
            ...this.state, isErrorCredential: 0, credentialIndex: null, editCredential: false,
            newCredential: {
                credentialName: '',
                lookupCredentialType: null,
                issuranceDate: '',
                expirationDate: '',
                issuingState: '',
                certificateOrLicenseNo: '',
                issuingAuthority: ''
            }
        }, () => {
            window.$("#addNewCredential").hide();
            window.$("#btnAddCredential").show();
        })
    }
    showAddress = () => {
        window.$("#addNewAddress").show();
        window.$("#btnAddModal").hide();
    }
    closeCredential = () => {
        window.$("#addNewCredential").hide();
        window.$("#btnAddCredential").show();
    }
    showCredential = () => {
        window.$("#addNewCredential").show();
        window.$("#btnAddCredential").hide();
    }
    cancel = () => {
        this.setState({ newAddress: this.initializeAddressFields }, () => {
            window.$("#addNewAddress").hide();
            window.$("#btnAddModal").show();
        });
    }
    handleChangeSelectedPhone = (event, id, type) => {
        let fields = this.state.selectedPhoneFields;
        var existingTPhoneArr = this.state.existingTPhoneArr;
        if (isNaN(event.target.value) || event.target.value === '' || event.target.value === null) {
            fields[event.target.name] = event.target.value;
        } else {
            fields[event.target.name] = Number(event.target.value);
        }
        if (event.target.name === 'phone' + id) {
            this.state.selectedPhoneFields['phone' + id] = fields[event.target.name];
            for (let i = 0; i < existingTPhoneArr.length; i++) {
                if (existingTPhoneArr[i].contactNo === id) {
                    existingTPhoneArr[i]['contactNo'] = fields[event.target.name];
                }
            }
        }
        if (event.target.name === 'type' + id) {
            this.state.selectedPhoneFields['type' + id] = fields[event.target.name];
            for (let i = 0; i < existingTPhoneArr.length; i++) {
                if (existingTPhoneArr[i].contactNo === id) {
                    existingTPhoneArr[i]['title'] = fields[event.target.name];
                }
            }
        }
        this.setState({
            selectedPhoneFields: fields, existingTPhoneArr: existingTPhoneArr
        })
    }
    deleteAddressCredential = (deleteId, success = false) => {
        this.setState({ deleteId: deleteId }, () => {
            if (success) {
                var deleteType = this.state.deleteType === 'ADDRESS' ? 'listAddress' : 'listCredential';
                let fields = Object.assign({}, this.state.fields);
                fields[deleteType].splice(this.state.key, 1);
                this.setState({ fields, key: null, deleteType: null })
            }
        });
    }
    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="editPersonModal" style={{ overflow: "scroll" }}>
                    <form action="" id="addschooldesig" name="addschooldesig">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content" >
                                <div className="modal-header">
                                    <h5 className="modal-title">{i18n.t('editPerson')}</h5>
                                    <button type="button" className="close" onClick={() => this.closeModal()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h6 className="text-primary"><b>{i18n.t('name')}</b></h6>
                                    <div className="panel-default">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('salutation')}</label>
                                                        <MarkersCommonSelect
                                                            name="lookupSalutation"
                                                            value={this.state.fields.lookupSalutation}
                                                            optArray={this.state.lookupSalutationList}
                                                            handleChange={this.handleChange}
                                                            onlySelect={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-4">
                                                    <div className="form-group">
                                                        <MarkersCommonSelect
                                                            label={i18n.t("nameSuffix")}
                                                            divClassName="mb-4  mb-lg-0"
                                                            name="lookupNameSuffix"
                                                            value={this.state.fields['lookupNameSuffix']}
                                                            optArray={this.props.lookupNameSuffixList}
                                                            handleChange={this.handleChange}
                                                            isError={this.state.isError}
                                                            isRequired={false}
                                                            optionLabelValue='id'
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-4">
                                                    <div className="form-group mb-4  mb-lg-0">
                                                        <label className="col-form-label">{i18n.t("firstName")} <span>*</span></label>
                                                        <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterFirstName')} name="firstName" onChange={this.handleChange} value={this.state.fields.firstName} />
                                                        <Validation data={{ field: i18n.t('firstName'), errorClass: 'firstName_error', value: this.state.fields.firstName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-4">
                                                    <div className="form-group mb-4  mb-lg-0">
                                                        <label className="col-form-label">{i18n.t("middleName")}</label>
                                                        <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterMiddleName')} name="middleName" onChange={this.handleChange} value={this.state.fields.middleName} />
                                                        <Validation data={{ field: i18n.t('middleName'), errorClass: 'middleName_error', value: this.state.fields.middleName, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.SCHOOL_NAME_REGEX }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-4">
                                                    <div className="form-group mb-0">
                                                        <label className="col-form-label">{i18n.t("lastName")} <span>*</span></label>
                                                        <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterLastName')} name="lastName" onChange={this.handleChange} value={this.state.fields.lastName} />
                                                        <Validation data={{ field: i18n.t('lastName'), errorClass: 'lastName_error', value: this.state.fields.lastName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-12 col-lg-6">
                                            <MarkersDatePicker
                                                value={this.state.fields.dateOfBirth}
                                                title={i18n.t('dateOfBirth')}
                                                name="dateOfBirth"
                                                onChange={this.handleChange}
                                            />
                                            <div className="form-group">
                                                <MarkersCommonSelect
                                                    label={i18n.t("gender")}
                                                    name="gender"
                                                    value={this.state.fields.gender}
                                                    optArray={this.state.genderTypeList}
                                                    handleChange={this.handleChange}
                                                    isError={this.state.isError}
                                                    isRequired={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-12 col-lg-6">
                                            <MarkersFileUpload
                                                label={i18n.t("selectPhoto")}
                                                isError={this.state.isError}
                                                isRequired={false}
                                                setFileField={this.setFileField}
                                                fileType={commonConfig.FILE_TYPE_IMAGE}
                                                isPreview={true}
                                                savedFileList={this.state.fileDetails}
                                                fileMaxSize={new sessionStore().returnMaxFileSize()}
                                                fileMinSize={new sessionStore().returnMinFileSize()}
                                                clearFile={this.state.clearFile}
                                                isDelete={true}
                                                deleteApiComponent={commonConfig.COMPONENT_RESOURCE}
                                                deleteApiEndPoint={endPointConfig.COMPONENT_DELETE_PERSON_IMAGE}
                                            />
                                        </div>
                                    </div>
                                    <h6 className="text-primary mt-3"><b>{i18n.t('email')}</b></h6>
                                    <div className="panel-default">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4  mb-md-0">
                                                        <label className="col-form-label">{i18n.t('primaryEmail')} <span>*</span></label>
                                                        <input type="text" maxLength={256} name="primaryEmail" value={this.state.fields.primaryEmail} className="form-control" onChange={this.handleChange} placeholder={i18n.t('enterPrimaryEmail')} disabled />
                                                        <Validation data={{ field: i18n.t('primaryEmail'), errorClass: 'primaryEmail_error', value: this.state.fields.primaryEmail, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-0">
                                                        <label className="col-form-label">{i18n.t('alternateEmail')}</label>
                                                        <input type="text" maxLength={256} name="alternateEmail" onChange={this.handleChange} value={this.state.fields.alternateEmail} className="form-control" placeholder={i18n.t('enterAlternateEmail')} />
                                                        <Validation data={{ field: i18n.t('alternateEmail'), errorClass: 'alternateEmail_error', value: this.state.fields.alternateEmail, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="text-primary"><b>{i18n.t('phone')}</b></h6>
                                    <div className="label-header ">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4">
                                                <label>{i18n.t('phoneType')}</label>
                                            </div>
                                            <div className="col-md-5 col-lg-6">
                                                <label>{i18n.t('phoneNumberWithCode')}</label>
                                            </div>
                                            <div className="col-md-3 col-lg-2">
                                                <label>{i18n.t('action')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-default">
                                        <div className="panel-body">
                                            <div className="outerClass" >
                                                <div className="row mainClass" style={{ "paddingBottom": "25px" }}>
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="form-group m-0">
                                                            <select className="custom-select" onChange={this.handleChangePhoneType} value={this.state.tempPhoneType}>
                                                                <option value="">{i18n.t('selectPhoneType')}</option>
                                                                {this.state.phoneTypeList.map((lookup, key) => {
                                                                    return <option key={key} value={lookup.title}>{lookup.title}</option>
                                                                })}
                                                            </select>
                                                            <Validation data={{ field: i18n.t('phoneType'), errorClass: 'PhoneType_error', value: this.state.tempPhoneType, isError: this.state.isErrorPhone, validationType: 'selectfield' }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5 col-lg-6">
                                                        <div className="form-group m-0">
                                                            <input type="text" maxLength={14} className="form-control" placeholder={i18n.t('enterPhone')} name="homePhoneNumber" onChange={this.handleChangePhone} value={this.state.tempPhoneNumber} />
                                                            <Validation data={{ field: i18n.t('phoneNumberWithCode'), errorClass: 'phoneNumberWithCode_error', value: this.state.tempPhoneNumber, isError: this.state.isErrorPhone, validationType: 'regExp', regExp: commonConfig.PHONE_NUMBER_ADD_PERSON, minLength: commonConfig.PHONE_NUMBER_MIN_LENGTH }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-lg-2">
                                                        <div className="form-group card mb-0">
                                                            <div className="text-center appendPlus">
                                                                {this.state.tempPhoneType !== '' && this.state.tempPhoneNumber !== ''
                                                                    ?
                                                                    <Link to="#" className="btn btn-outline-success btn-block mr-2 plus" onClick={this.fieldShowMore}>
                                                                        <i className="icon-plus3" ></i>
                                                                    </Link>
                                                                    :
                                                                    <Link to="#" className="btn btn-outline-success btn-block mr-2 plus" onClick={this.fieldShowMore} >
                                                                        <i className="icon-plus3" ></i>
                                                                    </Link>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.phoneShow()}
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="text-primary mt-3"><b>{i18n.t('address')}</b></h6>
                                    {this.addressShow()}
                                    <div className="text-center">
                                        {!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission &&
                                            <Link to="#" className="btn btn-primary m-b-15" id="btnAddModal" onClick={this.showAddress} data-toggle="toggle-visibility" data-targetlayer="#addNewAddress" data-destructlayer="#btnAddModal">
                                                <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewAddress')}
                                            </Link>}
                                    </div>
                                    <div className="panel-default" id="addNewAddress" style={{ display: 'none' }}>
                                        <div className="panel-header">
                                            <h6 className="panel-title">{i18n.t('address')} <label className="badge badge-primary text-uppercase m-l-15">{i18n.t('new')}</label></h6>
                                        </div>
                                        <div className="panel-body pt-0 pb-0">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('addressType')} <span>*</span></label>
                                                        <select className="custom-select" value={this.state.newAddress.lookupAddressType} name="lookupAddressType" onChange={this.handleChangeAddress}>
                                                            <option selected>{i18n.t('selectType')}</option>
                                                            {this.state.lookupAddressTypeList.map((lookup, key) => {
                                                                return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                            })}
                                                        </select>
                                                        <Validation data={{ field: i18n.t('addressType'), errorClass: 'addressType_error', value: this.state.newAddress.lookupAddressType, isError: this.state.isError, validationType: 'field' }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('address')} <span>*</span></label>
                                                        <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterAddress')} value={this.state.newAddress.addressLine} name="addressLine" onChange={this.handleChangeAddress} />
                                                        <Validation data={{ field: i18n.t('address'), errorClass: 'address_error', value: this.state.newAddress.addressLine, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.ADDRESS_SCHOOL }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('streetNoName')} </label>
                                                        <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterStreetNoName')} value={this.state.newAddress.streetNoName} name="streetNoName" onChange={this.handleChangeAddress} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('aprtSuiteRoomNo')} </label>
                                                        <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterAprtSuiteRoomNo')} value={this.state.newAddress.aprtSuiteRoomNo} name="aprtSuiteRoomNo" onChange={this.handleChangeAddress} />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('buildingSiteNo')} </label>
                                                        <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterBuildingSiteNo')} value={this.state.newAddress.buildingSiteNo} name="buildingSiteNo" onChange={this.handleChangeAddress} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <MarkersCommonSelect
                                                        label={i18n.t("country")}
                                                        name="countryCode"
                                                        value={this.state.newAddress['countryCode']}
                                                        optArray={this.props.countryList}
                                                        handleChange={this.handleChangeAddress}
                                                        isError={this.state.isError}
                                                        isRequired={true}
                                                    />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <MarkersCommonSelect
                                                        label={i18n.t("state")}
                                                        optionLabelValue="title"
                                                        name="state"
                                                        value={this.state.newAddress.state}
                                                        optArray={this.props.stateByCountryList}
                                                        handleChange={this.handleChangeAddress}
                                                        isError={this.state.isError}
                                                        isRequired={true}
                                                    />
                                                </div>
                                                {!!this.state.newAddress.countryCode && this.state.newAddress.countryCode === 'US' &&
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersCommonSelect
                                                            label={i18n.t("county")}
                                                            optionLabelValue="title"
                                                            name="county"
                                                            value={this.state.newAddress.county}
                                                            optArray={this.props.countyByStateList}
                                                            handleChange={this.handleChangeAddress}
                                                            isError={this.state.isError}
                                                            isRequired={false}
                                                        />
                                                    </div>}
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('city')} <span>*</span></label>
                                                        <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterCity')} value={this.state.newAddress.city} name="city" onChange={this.handleChangeAddress} />
                                                        <Validation data={{ field: i18n.t('city'), errorClass: 'city_error', value: this.state.newAddress.city, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('zipPostalCode')} <span>*</span></label>
                                                        <input type="text" maxLength={10} className="form-control" placeholder={i18n.t('enterZipPostalCode')} value={this.state.newAddress.zip} name="zip" onChange={this.handleChangeAddress} />
                                                        <Validation data={{ field: i18n.t('zipPostalCode'), errorClass: 'state_error', value: this.state.newAddress.zip, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.POSTAL_CODE_ZIP }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Geolocation</label>
                                                        <input type="text" className="form-control" name="" placeholder={i18n.t('enterGeoLocation')} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">                                                    
                                                    <label className="d-flex align-items-center mb-0">
                                                        <div className="fluid-switch-toggle cursor-pointer">
                                                            <input className="switch-toggle-state" type="checkbox" name="isDefault" checked={this.state.newAddress.isDefault} onChange={this.handleChangeAddress} />
                                                            <div className="switch-indicator"></div>
                                                            <div className="switch-activebg"></div>
                                                        </div>
                                                        <span className="switch-text">{i18n.t("default")}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body pt-0 text-right">
                                            {!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission &&
                                                <Link to="#" className="btn btn-outline-success m-r-5 addNewFieldAreaaa showAddButton1" onClick={this.saveAddress}>
                                                    <i className="icon-checkmark2"></i><span className="sr-only">{i18n.t('done')}</span>
                                                </Link>}
                                            {!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().deletePermission &&
                                                <Link to="#" className="btn btn-outline-warning" data-toggle="toggle-visibility" onClick={this.cancel} data-targetlayer="#btnAddModal" data-destructlayer="#addNewAddress">
                                                    <i className="icon-cross"></i><span className="sr-only">{i18n.t('cancel')}</span>
                                                </Link>}
                                        </div>
                                    </div>
                                    <h6 className="text-primary mt-3"><b>{i18n.t('credential')}</b></h6>
                                    {this.credentialShow()}
                                    <div className="text-center">
                                        {!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission &&
                                            <Link to="#" className="btn btn-primary m-b-15" id="btnAddCredential" onClick={this.showCredential} data-toggle="toggle-visibility" data-targetlayer="#addNewCredential" data-destructlayer="#btnAddCredential">
                                                <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewCredential')}
                                            </Link>}
                                    </div>
                                    <div className="panel-default" id="addNewCredential" style={{ display: 'none' }}>
                                        <div className="panel-header">
                                            <h6 className="panel-title">{i18n.t('credential')} <label className="badge badge-primary text-uppercase m-l-15">{i18n.t('new')}</label></h6>
                                        </div>
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('credentialName')}  <span>*</span></label>
                                                        <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('credentialName')} name="credentialName" onChange={this.handleChangeCredential} value={this.state.newCredential.credentialName} required />
                                                        <Validation data={{
                                                            field: i18n.t('credentialName'),
                                                            errorClass: 'credentialName_error',
                                                            value: this.state.newCredential.credentialName,
                                                            isError: this.state.isErrorCredential,
                                                            validationType: 'regExp', regExp: commonConfig.INSTITUITION_NAME
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('credentialType')} <span>*</span></label>
                                                        <select className="custom-select" onChange={this.handleChangeCredential} name="lookupCredentialType" value={this.state.newCredential.lookupCredentialType || ''}>
                                                            <option value="">
                                                                {i18n.t('select')}
                                                            </option>
                                                            {this.state.lookupCredentialType.map((lookup, key) => {
                                                                return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                            })}
                                                        </select>
                                                        <Validation data={{
                                                            field: i18n.t("credentialType"),
                                                            errorClass: 'credentialType_error',
                                                            value: this.state.newCredential.lookupCredentialType,
                                                            isError: this.state.isErrorCredential,
                                                            validationType: 'selectfield'
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <MarkersDatePicker
                                                        value={this.state.newCredential.issuranceDate}
                                                        title={i18n.t('issuingDate')}
                                                        name="issuranceDate"
                                                        onChange={this.handleChangeCredential}
                                                        isRequired={true}
                                                        isError={this.state.isErrorCredential}
                                                    />
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <MarkersDatePicker
                                                        value={this.state.newCredential.expirationDate}
                                                        title={i18n.t('expirationDate')}
                                                        name="expirationDate"
                                                        onChange={this.handleChangeCredential}
                                                        validationType="onlyDate"
                                                        validationStart={this.state.newCredential.issuranceDate}
                                                        validationEnd={this.state.newCredential.expirationDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('issuingState')}</label>
                                                        <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterIssuingState')} name="issuingState" value={this.state.newCredential.issuingState} onChange={this.handleChangeCredential} />
                                                        <Validation data={{
                                                            field: i18n.t("issuingState"),
                                                            errorClass: 'issuingState_error',
                                                            value: this.state.newCredential.issuingState,
                                                            isError: this.state.isErrorCredential,
                                                            validationType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('certificateOrLicenseNo')}</label>
                                                        <input type="text" maxLength={30} className="form-control" placeholder={i18n.t('enterCertificateLicenceNo')} name="certificateOrLicenseNo" value={this.state.newCredential.certificateOrLicenseNo} onChange={this.handleChangeCredential} />
                                                        <Validation data={{
                                                            field: i18n.t("certificateLicenceNo"),
                                                            errorClass: 'cretificateLicenceNo_error',
                                                            value: this.state.newCredential.certificateOrLicenseNo,
                                                            isError: this.state.isErrorCredential,
                                                            validationType: 'regExpNotRequired', regExp: commonConfig.LICENCE_NO
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('issuingAuthority')}</label>
                                                        <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterIssuingAuthority')} name="issuingAuthority" value={this.state.newCredential.issuingAuthority} onChange={this.handleChangeCredential} />
                                                        <Validation data={{
                                                            field: i18n.t("issuingAuthority"),
                                                            errorClass: 'issuingAuthority_error',
                                                            value: this.state.newCredential.issuingAuthority,
                                                            isError: this.state.isErrorCredential,
                                                            validationType: 'regExpNotRequired', regExp: commonConfig.CERTIFICATE_NUMBER
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body pt-0 text-right">
                                            {!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission &&
                                                <Link to="#" className="btn btn-outline-success m-r-5 addNewFieldAreaaa showAddButton1" onClick={this.saveCredential}>
                                                    <i className="icon-checkmark2"></i><span className="sr-only">{i18n.t('done')}</span>
                                                </Link>}
                                            {!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().deletePermission &&
                                                <Link to="#" className="btn btn-outline-warning" data-toggle="toggle-visibility" onClick={this.closeCredential} data-targetlayer="#btnAddCredential" data-destructlayer="#addNewCredential">
                                                    <i className="icon-cross " ></i><span className="sr-only">{i18n.t('cancel')}</span>
                                                </Link>}
                                        </div>
                                    </div>
                                    <h6 className="text-primary m-t-10"><b>{i18n.t('occupation')}</b></h6>
                                    <div className="panel-default">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('type')} {this.state.isOccupationFieldsNotEmpty && <span>*</span>}</label>
                                                        <select className="custom-select" name="lookupOccupationType" onChange={this.handleChange} value={this.state.fields.lookupOccupationType}>
                                                            <option value="">{i18n.t('select')}</option>
                                                            {this.props.lookupPersonOccupationTypeList.map((lookup, key) => {
                                                                return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                            })}
                                                        </select>
                                                        {this.state.isOccupationFieldsNotEmpty && <Validation data={{
                                                            field: i18n.t("type"),
                                                            errorClass: 'type_error',
                                                            value: this.state.fields.lookupOccupationType,
                                                            isError: this.state.isError,
                                                            validationType: 'selectfield'
                                                        }} returnValidation={this.functionValidation} />}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('title')} {this.state.isOccupationFieldsNotEmpty && <span>*</span>}</label>
                                                        <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterTitle')} name="occupationTitle" onChange={this.handleChange} value={this.state.fields.occupationTitle} />
                                                        <Validation data={{ field: i18n.t('title'), errorClass: 'occupationTitle_error', value: this.state.fields.occupationTitle, isError: this.state.isError, validationType: this.state.isOccupationFieldsNotEmpty ? 'regExp' : 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-4"></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('designation')}</label>
                                                        <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterDesignation')} name="designation" onChange={this.handleChange} value={this.state.fields.designation} />
                                                        <Validation data={{ field: i18n.t('designation'), errorClass: 'designation_error', value: this.state.fields.designation, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('monthlyIncome')}</label>
                                                        <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterMonthlyIncome')} name="monthlyIncome" onChange={this.handleChange} value={this.state.fields.monthlyIncome || ''} />
                                                        <Validation data={{ field: i18n.t('monthlyIncome'), errorClass: 'monthlyIncome_error', value: this.state.fields.monthlyIncome, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.FLOAT_VAL }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('workEmail')}</label>
                                                        <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterWorkEmail')} name="workEmail" onChange={this.handleChange} value={this.state.fields.workEmail} />
                                                        <Validation data={{ field: i18n.t('workEmail'), errorClass: 'workEmail_error', value: this.state.fields.workEmail, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission &&
                                        <button type="button" class="btn btn-success" onClick={this.handleSubmit}>{i18n.t('update')}</button>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <MarkersDelete
                    title={this.state.deleteType === 'ADDRESS' ? i18n.t("deleteAddress") : i18n.t("deleteCredential")}
                    deleteId={this.state.deleteId}
                    componentName={commonConfig.COMPONENT_RESOURCE}
                    endPoint={this.state.deleteType === 'ADDRESS' ? commonConfig.COMPONENT_ADDRESS_DELETE_BYID : commonConfig.COMPONENT_PERSON_CREDENTIAL_DELETE_BYID}
                    setDeleteSuccess={this.deleteAddressCredential}
                />
            </React.Fragment >
        );
    }
}
const mapStateToProps = state => {
    return {
        countryList: state.commonStore.countryList,
        lookupSalutationList: state.commonStore.lookupSalutationList,
        lookupAddressTypeList: state.commonStore.lookupAddressTypeList,
        saveSchoolPerson: state.commonStore.saveSchoolPerson,
        lookupCredentialTypeList: state.commonStore.lookupCredentialTypeList,
        lookupPersonOccupationTypeList: state.commonStore.lookupPersonOccupationTypeList,
        stateByCountryList: state.commonStore.stateByCountryList,
        countyByStateList: state.commonStore.countyByStateList,
        lookupNameSuffixList: state.commonStore.lookupNameSuffixList,
        getPerson: state.commonStore.getPerson
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onGetLookup: (categoryType) => dispatch(actionsCommon.callLookupApi(categoryType)),
        onGetCountryList: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'country')),
        onUpdatePersonSucess: (fileData) => dispatch(actionsCommon.callCommonSaveApi(fileData, 'School Person', true)),
        onGetStateByCountry: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'state by country')),
        onGetCountyByState: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'county by state')),
        onCommonResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs)),
        onGetById: (executeArgs) => dispatch(actionsCommon.callCommonGetByApi(executeArgs, 'person'))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersCommonPersonEdit);
