import { Component } from 'react';
import i18n from '../../../i18n';
class markersBreadCrumb extends Component {
    returnContent(url) {
        var content = {
            '/transport/manageRoutes':
                { 'name': i18n.t('route'), 'url': 'transport/manageRoutes', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/routeDetails':
                { 'name': i18n.t('manageRoutes'), 'url': 'transport/routeDetails', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/manageRouteDetails':
                { 'name': i18n.t('routeDetails'), 'url': 'transport/manageRouteDetails', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/manageStaffRouteDetails':
                { 'name': i18n.t('manageStaffDetails'), 'url': 'transport/manageStaffRouteDetails', 'parent': [{ 'name': i18n.t('Transport'), 'url': '#' }] },
            '/transport/manageStaffRouteDetails/addStaffRouteDetails':
                { 'name': i18n.t('addStaffDetails'), 'url': 'transport/addStaffRouteDetails', 'parent': [{ 'name': i18n.t('manageStaffDetails'), 'url': '/transport/manageStaffRouteDetails' }] },
            '/transport/manageStudentRouteDetails':
                { 'name': i18n.t('manageStudentRouteDetails'), 'url': 'transport/manageStudentRouteDetails', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/addStudentRouteDetails':
                { 'name': i18n.t('manageStudentRouteDetails'), 'url': 'transport/addStudentRouteDetails', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/manageVehicle':
                { 'name': i18n.t('vehicle'), 'url': 'transport/manageVehicle', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/vehicleDetails':
                { 'name': i18n.t('manageVehicles'), 'url': 'transport/vehicleDetails', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/manageStop':
                { 'name': i18n.t('stops'), 'url': 'transport/manageStop', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/manageTransportationStaff':
                { 'name': i18n.t('staff'), 'url': 'transport/manageTransportationStaff', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/transportRequest':
                { 'name': i18n.t('TRAN_REQ_APVL'), 'url': 'transport/transportRequest', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/manageVehicleLog':
                { 'name': i18n.t('manageVehicleLog'), 'url': 'transport/manageVehicleLog', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageVehicleLog' }] },
            '/transport/monitorVehicle':
                { 'name': i18n.t('TRAN_MON_VEH'), 'url': 'transport/monitorVehicle', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/monitorVehicle' }] },
            '/transport/transportRequestGuardian':
                { 'name': i18n.t('TRAN_REQ'), 'url': 'transport/transportRequestGuardian', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/manageVehicleRoute':
                { 'name': i18n.t('vehicleRouteTitle'), 'url': 'transport/manageVehicleRoute', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/requestPickupDrop':
                { 'name': i18n.t('TRAN_REQ_PIDR'), 'url': 'transport/requestPickupDrop', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/takeAttendance':
                { 'name': i18n.t('takeAttendance'), 'url': 'transport/takeAttendance', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/takeAttendance' }] },
            '/transport/requestPickupDropApproval':
                { 'name': i18n.t('requestPickupDropApproval'), 'url': 'transport/requestPickupDropApproval', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/manageRoutes' }] },
            '/transport/customNotification':
                { 'name': i18n.t('customNotification'), 'url': 'transport/customNotification', 'parent': [{ 'name': i18n.t('Transport'), 'url': '/transport/takeAttendance' }] }
        };
        return content[url];
    }
}
export default markersBreadCrumb;