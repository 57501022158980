import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyBF6PX94qHJYh3vXWdPf-jRYP9AEJqaFHs");
Geocode.setLanguage("en");
Geocode.enableDebug();
class MarkersMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: 29.74990,
            longitude: -95.358421,
            key: new Date().getTime()
        };
    }
    componentDidMount = () => {
        this.setLatLong()
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.data.locationString !== prevProps.data.locationString || this.props.data.latitude !== prevProps.data.latitude || this.props.data.longitude !== prevProps.data.longitude){
            this.setLatLong()
        }
    }

    setLatLong = () => {
        const { locationString, latitude, longitude } = this.props.data;
        if (latitude && longitude){
            this.setState({ latitude, longitude, key: new Date().getTime() })
        } else if (locationString) {
            Geocode.fromAddress(locationString).then(
                (response) => {
                  const { lat, lng } = response.results[0].geometry.location;
                  this.setState({ latitude: lat, longitude: lng, key: new Date().getTime() })
                },
                (error) => {
                  this.setState({ latitude: 29.74990, longitude: -95.358421, key: new Date().getTime() })
                }
              );
        } 
    }

    returnMap() {
        let returnJsx = '';
        returnJsx = <Map
            key={this.state.key}
            google={this.props ? this.props.google : null}
            style={this.props.data.style}
            initialCenter={{
                lat: this.state.latitude,
                lng: this.state.longitude
            }}
            zoom={this.props.data.zoomLevel}
            onClick={this.props.data.classObj[this.props.data.clickFunc]}
        />

        return returnJsx;
    }

    render() {
        return (<div>{this.returnMap()}</div>);
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyBF6PX94qHJYh3vXWdPf-jRYP9AEJqaFHs')
})(MarkersMap)