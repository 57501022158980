import React from "react";
import Validation from './validationComponent';
import i18n from '../../../i18n';
import DatePicker from "react-datepicker";
import sessionStore from './sessionStoreComponent';
import "react-datepicker/dist/react-datepicker.css";
let moment = require('moment');
function MarkersDatepicker(props) {
    const {
        divClassName, onlyField, title, isRequired, isError, name, value, dateFormat, placeholder, onChange, startDate, endDate,
        isRemoveIcon, disabled, readOnly, validationType, validationStart, validationEnd, validationAssociatedField, onlyMonthYearPicker, customValidationMessage
    } = props;
    const format = !!dateFormat ? dateFormat : new sessionStore().returnDateFormat()
    let attribute = !!props.attribute && Object.keys(props.attribute).length !== 0 ? { ...props.attribute } : {}
    let validationObj = { field: !!customValidationMessage ? customValidationMessage : title, errorClass: name + '_error', value: value || '', isError: isError, validationType: validationType === 'onlySRDate' ? 'fieldDynamic' : 'field' }
    if (!!validationType && !!validationStart && !!validationEnd) {
        validationObj['validationType'] = validationType;
        validationObj['startDate'] = validationStart;
        validationObj['endDate'] = validationEnd;
    }
    if (!!validationType && !!validationAssociatedField) {
        validationObj['validationType'] = validationType;
        validationObj['associatedField'] = validationAssociatedField;
    }
    attribute = { ...attribute, popperClassName: "reactDatepicker-container" }
    attribute = { ...attribute, className: "form-control reactDatepicker" }
    attribute = { ...attribute, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select" }
    attribute = { ...attribute, placeholderText: !!placeholder ? placeholder : i18n.t("selectDate") }
    if (!!value && !!new Date(value))
        attribute = { ...attribute, selected: new Date(value.slice(0, 10)) }
    if (!!format)
        attribute = { ...attribute, dateFormat: format.replaceAll('D', 'd').replaceAll('Y', 'y') }
    if (!!startDate)
        attribute = { ...attribute, minDate: new Date(startDate.slice(0, 10)) }
    if (!!endDate)
        attribute = { ...attribute, maxDate: new Date(endDate.slice(0, 10)) }
    if (!!disabled)
        attribute = { ...attribute, disabled }
    if (!!readOnly)
        attribute = { ...attribute, readOnly }
    if (!!onlyMonthYearPicker)
        attribute = { ...attribute, showMonthYearPicker: true }
    if (!!onChange)
        attribute = { ...attribute, onChange: selectedValue => !!selectedValue ? onChange({ target: { value: moment(selectedValue).format("YYYY-MM-DD"), name, type: 'date' } }) : null }
    const fieldResult = !!isRemoveIcon ?
        <div className="input-group">
            <div className="react-datepicker-group">
                <DatePicker {...attribute} />
            </div>
        </div>
        :
        <React.Fragment>
            <div className="input-group">
                <div className="react-datepicker-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className="icon-calendar22"></i></span>
                    </div>
                    <DatePicker {...attribute} popperProps={{ positionFixed: true }} />
                    {!!props.isClearable &&
                        <button type="button" className="react-datepicker__close-icon" onClick={props.clearFunction} style={{ zIndex: 999 }}></button>
                    }
                </div>
            </div>
            {(!!isRequired || (validationType === 'onlyDate')) &&
                <Validation data={validationObj} />
            }
        </React.Fragment>
    return !!onlyField ?
        fieldResult :
        <div className={!!divClassName ? divClassName : "form-group"}>
            <label className="col-form-label">{title}{!!isRequired && <span> *</span>}</label>
            {fieldResult}
        </div>
}
export default MarkersDatepicker;