import { Route } from 'react-router';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sharedActionCreators } from '../components/shared/store/sharedAction';
import asyncComponent from './asyncComponent';
import sessionStore from '../../src/components/common/component/sessionStoreComponent';
import { hostArray } from '../hostConfig';
const routeList = [
    'manageVehicle',
    'routeDetails',
    'manageRoutes',
    'manageStop',
    'manageTransportationStaff',
    'manageRouteDetails',
    'transportRequest',
    'transportRequest/addRequest',
    'manageVehicleLog',
    'transportRequestGuardian',
    'manageVehicleRoute',
    'requestPickupDrop',
    'takeAttendance',
    'manageStaffRouteDetails',
    'manageStaffRouteDetails/addStaffRouteDetails',
    'requestPickupDropApproval',
    'manageStudentRouteDetails',
    'addStudentRouteDetails',
    'customNotification'
    /*'vehicleDetails'*/
]
let routeState = {}
for (let i = 0; i < routeList.length; i++) {
    routeState = {
        ...routeState,
        [routeList[i]]: asyncComponent(() =>
            import(`../components/view/${routeList[i]}/homeComponent`).then(module => module.default)
        )
    }
}
const MarkersCommonRoute = asyncComponent(() =>
    import('../components/common/component/markersCommonRoute').then(module => module.default)
);
export class PrivateRoute extends Route {
    render() {
        if (new sessionStore().returnSessionToken() == null) {
            // return <Redirect to="/sso" />;
            window.location.href = hostArray[new sessionStore().returnHostEnvironment()].home + '/sso';
        }
        return (
            <div>
                {routeList.map((route,i) => {
                    return <Route key={i} exact path={`/transport/${route}`} component={routeState[route]} />
                })}
                <Route exact path="/transport/commonRoute" component={MarkersCommonRoute} />
            </div>
        )
    }
}
export default connect(
    (state) => { const { sharedStore } = state; return { state: { sharedStore } }; },
    (dispatch) => { return { actions: bindActionCreators(Object.assign({}, sharedActionCreators), dispatch) }; }
)(PrivateRoute);
