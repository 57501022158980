export class commonConfig {
    static DASH_VALUE = "--";
    static METHOD_TYPE_POST = "POST";
    static METHOD_TYPE_GET = "GET";
    static COMPONENT_LOGIN = 'authentication';
    static COMPONENT_LOGIN_VALIDATE_SAVE = 'v1/authentication/authenticate';
    static COMPONENT_REGENARATE_TOKEN = 'v1/authentication/regenaratetoken';
    static COMPONENT_LOGIN_SSO_VALIDATE_SAVE = 'v1/authentication/authenticatessouser';
    static COMPONENT_RESOURCE = 'resource';
    static COMPONENT_CONTROLPANEL = 'controlpanel';
    static COMPONENT_STATEREPORTING = 'statereporting';
    static COMPONENT_RESOURCE_GET_RESOURCES = 'v1/resource/GetAllResourceRecords?resourceType=';
    static COMPONENT_RESOURCE_GET_USER_LAST_SESSION = 'v1/appmenu/GetLastSessionOfUser';
    static COMPONENT_RESOURCE_SET_USER_LAST_SESSION = 'v1/appmenu/SetLastSessionOfUser';
    static COMPONENT_RESOURCE_UPDATE_USER_PREFERENCE = 'v1/userpreference/updateuserpreference';
    static COMPONENT_RESOURCE_GET_USER_PREFERENCE_OTP = 'v1/userpreference/sendresetpassswordotp';
    static COMPONENT_RESOURCE_FORGOT_PASSWORD = 'v1/userpreference/forgotpassword';
    static COMPONENT_RESOURCE_CONFIRM_OTP = 'v1/userpreference/forgotpasswordconfirmation';
    static COMPONENT_RESOURCE_RESET_PASSWORD = 'v1/userpreference/resetpassword';
    static COMPONENT_RESOURCE_GET_LOOKUPS = 'v1/lookup/GetByLookupCategory';
    static COMPONENT_RESOURCE_GET_LOOKUPS_MERGED = 'v1/lookup/getbylookupcategorymerged';
    static COMPONENT_RESOURCE_GET_STATESPECIFIC_LOOKUPS = 'v1/lookup/getbylookupstatespecific';
    static COMPONENT_RESOURCE_GET_COUNTRY = 'v1/lookup/GetAllCountryList';
    static COMPONENT_SAVE_FILE = 'v1/filemanager/upload';
    static COMPONENT_GET_MENUS = 'v1/appmenu/getusermodulesandmenus?schoolid=';
    static COMPONENT_GET_USER_MENUS = 'v1/appmenu/getusermoduledetails?schoolid=';
    static COMPONENT_GET_NOTIFICATIONS = 'v1/notification/getMessages';
    static COMPONENT_MARKASREAD_NOTIFICATIONS = 'v1/notification/markAsRead';
    static COMPONENT_DOWNLOAD_FILE = 'v1/resource/downloadfile?fileInfoId=';
    static COMPONENT_DOWNLOAD_EMAIL_FILE = 'v1/adminsendemail/downloadattachmentfile?fileId=';
    static COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY = 'v1/lookup/getallstatebycountry?countryCode=';
    static COMPONENT_RESOURCE_GET_COUNTY_BY_STATE = 'v1/lookup/getallcountybystate?state=';
    static COMPONENT_RESOURCE_GET_COLOUR = 'v1/colour/getallcolour';
    static COMPONENT_GET_SYSTEM_MAINTENANCE_BY_USER_ID = 'v1/systemmaintenance/getsystemmaintenancevaluebycustomerid?CustomerId=';
    static COMPONENT_GETSTATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/getdynamicuicomponentdetails';
    static COMPONENT_SAVE_STATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/postdynamicuicomponentfieldvalues';
    // notifications
    static COMPONENT_GET_RECEIVE_MESSAGES_LIST = 'v1/communication/getallreceivemessagesbyroleid?roleid=';
    //school
    static COMPONENT_SCHOOL = 'school';
    static COMPONENT_SCHOOL_SESSION_GET_FOR_USER = 'v1/schoolsession/GetSessionForUser';
    static COMPONENT_GET_SCHOOL_BASED_ROLE_LIST_BY_USERID = 'v1/schoolrole/getschoolrolesbyuserid';
    static COMPONENT_GET_SCHOOL_TIMEZONE_NAME = 'v1/GeneralInfo/GetSchoolAvailableTimeZone?schoolId=';
    static COMPONENT_GET_ROLE_BASED_PERMISSIONS = 'v1/schoolrolepermissionset/getpermissionsetbyroleid?roleId=';
    static COMPONENT_SCHOOL_CALENDAR_GETDEFAULT = 'v1/Calendar/GetDefaultCalendar?id=';
    static COMPONENT_SCHOOL_MARKING_PERIOD_FORSESSIONID = 'v1/Markingperiod/GetMarkingPeriodsListForSession';
    static COMPONENT_SCHOOL_GENERALINFO_GETBYID = 'v1/GeneralInfo/GetSchoolInfoById?id=';
    static COMPONENT_SCHOOL_SECTION_GETBYID = 'v1/schoolsection/GetSchoolSectionById?id=';
    static COMPONENT_SCHOOL_GRADELEVEL_GETBYID = 'v1/schoolgradelevel/getschoolgradelevelbyid';
    static COMPONENT_SCHOOL_SESSION_GETBYID = 'v1/schoolsession/GetSchoolSessionById';
    static COMPONENT_SCHOOL_ROOM_GETBYID = 'v1/classroom/GetClassRoomById';
    static COMPONENT_DEPARTMENT_GETBYID = 'v1/SchoolDepartment/GetSchoolDepartmentById';
    static COMPONENT_SCHOOL_GETALL_GRADESCALE_CATEGORY_DETAILS = 'v1/schoolgradescale/getschoolgradescalebyid?id=';
    static COMPONENT_SCHOOL_PERIOD_GETBYID = 'v1/SchoolPeriod/GetSchoolPeriodById';
    static COMPONENT_SCHOOL_ROLE_GETBYID = 'v1/SchoolRole/GetSchoolRoleById';
    static COMPONENT_SCHOOL_MARKING_PERIOD_GETBYID = 'v1/MarkingPeriod/GetMarkingPeriodById';
    static COMPONENT_SCHOOL_GRADELEVEL_GETALL = 'v1/schoolgradelevel/getallschoolgradelevel?';
    static COMPONENT_GET_SYSTEM_DEPARTMENT_LIST = 'v1/manageuser/getdepartmentbyschool?schoolId=';
    static COMPONENT_GET_SCHOOL_COUNTRY_CODE = 'v1/generalinfo/getschoolcountrycode?schoolId=';
    static COMPONENT_GET_SCHOOL_STATE_CODE = "v1/generalinfo/getschoolstatecode?schoolId=";
    //staff
    static COMPONENT_STAFF = 'staff';
    static COMPONENT_STAFF_DEMOGRAPHICINFO_GETBYID = 'v1/staffinfo/getstaffinfobyid?id=';
    static COMPONENT_STAFF_CONTACT_GETBYID = 'v1/StaffContact/GetStaffContactById';
    static COMPONENT_CREDENTIAL_GET_ALL = 'v1/credential/getallcredentialbyschoolid?schoolId';
    static COMPONENT_STUDENT_GET_NOTIFIABLE_CONTACTS = 'v1/studentinfo/getstudentnotifiablecontactbysearch?';
    static COMPONENT_STUDENT_SEND_NOTIFIABLE_CONTACTS = 'v1/studentinfo/generatepasswordforstudentnotifiablecontact?personIds=';
    //student
    static COMPONENT_STUDENT = 'student';
    static COMPONENT_STUDENT_GET_BY_ID = 'v1/studentinfo/GetStudentInfoById?id=';
    static COMPONENT_STUDENT_GUARDIAN_GET_BY_ID = 'v1/studentguardian/getstudentguardianbyid';
    static COMPONENT_STUDENT_GUARDIAN_GET_ALL = 'v1/studentguardian/getstudentallguardian';
    //course
    static COMPONENT_COURSE = 'course';
    static COMPONENT_COURSE_SUBJECT_GET_BY_ID = 'v1/coursesubjectsoffered/getcoursesubjectsofferedbyid';
    static COMPONENT_COURSE_GET_BY_ID = 'v1/courseinfo/getcourseinfobyid';
    static COMPONENT_COURSE_SECTION_GET_BY_ID = 'v1/coursesection/getcoursesectionbyid?id=';
    static COMPONENT_TRANSPORT = 'transport';
    //Person
    static COMPONENT_PERSON_GETALL = 'v1/Person/GetAllPersonList?schoolId=';
    static COMPONENT_PERSON_GETBYID = 'v1/Person/GetPersonById?id=';
    static COMPONENT_PERSON_SAVE = 'v1/Person/SavePerson';
    static COMPONENT_PERSON_UPDATE = 'v1/Person/UpdatePerson';
    static COMPONENT_PERSON_DELETE = 'v1/Person/DeletePerson';
    static COMPONENT_CUSTOMFIELD_SAVE = 'v1/customfieldvalue/saveupdatecustomfieldvalue';
    static COMPONENT_GETCUSTOMFIELD_VALUE = 'v1/customfieldgroup/GetCustomFieldGroupValueBySchoolIdAndMenuCode';
    static COMPONENT_CUSTOM_FIELD_CATEGORY_GET_ALL = 'v1/appmenu/GetUserMenuDetailsForMenuCode?menuCode=';
    static COMPONENT_PERSON_CREDENTIAL_DELETE_BYID = 'v1/Person/DeletePersonCredential';
    //Person address 
    static COMPONENT_ADDRESS_DELETE_BYID = 'v1/Person/DeletePersonAddress';
    //Global Search
    static COMPONENT_SEARCH_RESULT = 'v1/Search/GetSearchResults';
    static COMPONENT_ADVANCED_SEARCH_FIELDS = 'v1/Search/GetAdvancedSearchFields';
    //DashBoard
    static COMPONENT_GET_DASHBOARD_ADMIN_SCHOOL = 'v1/Dashboard/GetDashboardDetail';
    static COMPONENT_GET_DASHBOARD_ADMIN_ATTN_SCHOOL = 'v1/Dashboard/GetAttendanceCountAdminDashboard?schoolId=';
    static COMPONENT_GET_DASHBOARD_ANNOUNCEMENT = 'v1/Dashboard/GetAnnouncementByUserRole?roleId=';
    static COMPONENT_DASHBOARD_CALENDAR_EVENT_DAY = 'v1/Dashboard/GetCalendarEventsForDay';
    static COMPONENT_DASHBOARD_CALENDAR_EVENT_MONTH = 'v1/Dashboard/GetCalendarEventsForMonth';
    //Dynamic Form
    static COMPONENT_SR_DISPLAY_STATE_REPORT_COMPONENT = 'v1/dynamicuicomponent/getdisplaystatereportcomponent?stateCode=';
    static COMPONENT_GET_SCHOOL_WISE_DYNAMIC_FORM = 'v1/dynamicform/getschoolwisedynamicform';
    static COMPONENT_GET_ALL_SCHOOL_WISE_DISTINCT_FORM = 'v1/dynamicform/getdistinctformschoolwise';
    static COMPONENT_SAVE_DYNAMIC_FORM_VALUE = 'v1/dynamicform/savedynamicformvalue';
    //Generate Student Password Scheduler
    static COMPONENT_STUDENT = 'student';
    static COMPONENT_GENERATE_STUDENT_PASSWORD_SCHEDULER = 'v1/StudentInfo/TaskForStudentPasswordGeneration';
    /***** Get UserProfile picture *****/
    static COMPONENT_GET_PROFILE_PICTURE_BY_USER_ID = 'v1/resource/getprofilepicbyuserid?id=';
    //Session Storage
    //static SESSION_LOOKUPS = 'markersLookups';
    static SESSION_CUSTOM_CATEGORY = 'markersCustomCategory';
    static SESSION_LAST_USER_DETAILS = 'markersLastUserSessionDetails';
    static SESSION_DISTRICTID = 'markersDistrictId';
    static SESSION_SCHOOLID = 'markersSchoolId';
    static SESSION_SCHOOLYEAR = 'markersSchoolYear';
    static SESSION_SCHOOLSESSION = 'markersSchoolSession';
    static SESSION_ID = 'markersSessionId';
    static SESSION_MARKINGPERIOD_ID = 'markersSessionMarkingPeriodId';
    static SESSION_ENVIRONMENT = 'markersEnvironment';
    static SESSION_MICRO_SERVICE = 'markersService';
    static SESSION_SCHOOLNAME = 'markersSchoolName';
    static SESSION_SCHOOL_PHOTO = 'markersSchoolPhoto';
    static SESSION_SCHOOLADDRESS = 'markersSchoolAddress';
    static SESSION_TOKEN = 'markersToken';
    static SESSION_SSO_TOKEN = 'markersSsoToken';
    static SESSION_LOGGED_USER_EMAIL = 'markersLoggedUserEmail';
    static SESSION_LANGUAGE = 'i18nextLng';
    static SESSION_DATE_FORMAT = 'markersDateFormat';
    static DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
    static SESSION_CALENDARID = 'markersCalendarId';
    static SESSION_ROUTEPATH = 'markersRoutePath';
    static SESSION_USER_ID = 'markersUserId';
    static SESSION_USER_NAME = 'markersUserName';
    static SESSION_PHONE_FORMAT = 'markersPhoneFormat';
    static SESSION_NEWPERSONID = 'markersNewPersonId';
    static SESSION_NEWPERSONNAME = 'markersNewPersonName';
    static SESSION_IDLETIMER = 'markersIdleTimer';
    static SESSION_ROLE_BASED_PERMISSION_LIST = 'markersRoleBasedPersmissionList';
    static SESSION_CURRENT_PAGE_PERMISSION = 'markersCurrentPagePermission';
    static SESSION_SCHOOL_BASED_ROLE_LIST = 'markersSchoolBasedRoleList';
    static SESSION_SCHOOL_BASED_MULTI_ROLE_LIST = 'markersUserSelectedSchoolRoleList';
    static SESSION_SELECT_SCHOOL = "markersSchoolSelect";
    static SESSION_MP_SCHOOL = "markersSchoolSessionMp";
    static SESSION_LOGGED_USER_ROLE = 'markersUserRoleId';
    static SESSION_USER_ROLE = 'markersUserRole';
    static SESSION_MANAGE_INCIDENT_TYPE = 'markersManageIncidentType';
    static SESSION_MENU_CODE = 'markersMenuCode';
    static SESSION_NEXT_CLASS_SHOW = 'markersNextClass';
    static SESSION_NEXT_CLASS_START = 'markersNextClassStart';
    static SESSION_LOGIN_URL = 'markersLoginUrl';
    static SESSION_TIME_ZONE = 'markersTimeZone';
    static SESSION_FILE_MAXSIZE = 'defaultMaxFileSize';
    static SESSION_FILE_MINSIZE = 'defaultMinFileSize';
    static SESSION_DISTRICT_LOGO = 'markersDistrictLogo';
    static SESSION_IS_DASHBOARD = 'markersDashBoardLogoShow';
    static SESSION_IS_SCHOOLREPORT = 'markersSchoolReportLogoShow';
    static SESSION_IS_DISTRICTREPORT = 'markersDistrictReportLogoShow';
    static SESSION_DISTRICT_LOGO_FLAG = 'markersDistrictLogoFlag';
    static SESSION_STATECODE = 'markersStateCode';
    static SESSION_COUNTRYCODE = 'markersCountryCode';
    //Students
    static SESSION_STUDENT_GRADE_LEVEL_ID = 'markersStudentGradeLevelId';
    static SESSION_STUDENTID = 'markersStudentId';
    static SESSION_STUDENTNAME = 'markersStudentName';
    static SESSION_STUDENT_ENROLLMENT_ID = 'studentEnrollmentId';
    static SAVE_TYPE = 'updated';
    static SESSION_STUDENT_PHOTO = 'markersStudentPhoto';
    static SESSION_STUDENTVISAFLAG = 'markersStudentVisaFlag';
    static ADDRESS_COUNTY = "county"
    static SESSION_GUARDIAN_ID = 'markersGuardianId';
    static SESSION_STUDENT_CODE = "markersStudentCode";
    static SESSION_STUDENT_GRADE_LEVEL_TITLE = 'markersStudentGradeLevelTitle';
    static GRADE_LEVEL_TITLE = /^(?!\s)[0-9a-zA-Z\s]+$/i;
    static LOOKUP_EXIT_WITHDRAWAL_STATUS_DEFAULT_PERMANENT = 5201;
    static DEFAULT_ENROLLMENT_STATUS = 5002;
    //Staffs
    static SESSION_STAFFID = 'markersStaffId';
    static SESSION_STAFFNAME = 'markersStaffName';
    static SESSION_PERSONID = 'markersPersonId';
    static SESSION_STAFF_PHOTO = 'markersStaffPhoto';
    static JOB_CATEGORY_ID = "jobCategoryId"
    static SESSION_STAFF_CONTACT_ID = 'markersGuardianId'
    static LEAVE_STATUS_TYPE_DISCARDED = 'Discarded';
    //LookUp Category
    static LOOKUP_ENTITY_TYPE = 'ENTITY_TYPE';
    static LOOKUP_SCHOOL_TYPE = 'SCHOOL_TYPE';
    static LOOKUP_SCHOOL_LEVEL = 'SCHOOL_LEVEL';
    static LOOKUP_SCHOOL_FOCUS = 'SCHOOL_FOCUS_TYPE';
    static LOOKUP_ADDRESS_TYPE = 'OFFICE_ADDRESS_TYPE';
    static LOOKUP_PHONE_TYPE = 'PHONE_TYPE';
    static LOOKUP_EMAIL_TYPE = 'EMAIL_TYPE';
    static LOOKUP_SCHOOL_DESIGNATION_TYPE = 'SCHOOL_DESIGNATION_TYPE';
    static LOOKUP_SCHOOL_IDENTIFICATION_SYSTEM = 'SCHOOL_IDENTIFICATION_SYSTEM';
    static LOOKUP_ACCREDITATION_AGENCY = 'ACCREDITATION_AGENCY';
    static LOOKUP_HE_ACCREDITATION_STATUS = 'HE_ACCREDITATION_STATUS';
    static LOOKUP_ADMIN_FUND_CONTROL = 'ADMIN_FUND_CONTROL';
    static LOOKUP_SCHOOL_OPERATIONAL_STATUS = 'SCHOOL_OPERATIONAL_STATUS';
    static LOOKUP_SCHOOL_IMPROVEMENT_STATUS = 'SCHOOL_IMPROVEMENT_STATUS';
    static LOOKUP_SCHOOL_GRADE_LEVEL = 'SCHOOL_GRADES';
    //static LOOKUP_SCHOOL_CALENDAR_VISIBILITY = 'SCHOOL_ROLE';
    static LOOKUP_SCHOOL_FIELD_DATA_TYPE = 'DATA_TYPE';
    static LOOKUP_SCHOOL_FIELD_DATE_FORMAT = 'SCHOOL_FIELD_DATE_FORMAT';
    static LOOKUP_SCHOOL_FIELD_TYPE = 'FIELD_TYPE';
    static LOOKUP_ROLE_TYPE = 'SCHOOL_ROLE';
    static LOOKUP_SALUTATION_TYPE = 'SALUTATION';
    static LOOKUP_SALUTATION_STAFF_TYPE = 'SALUTATION_STAFF';
    static LOOKUP_MARKINGPERIOD_TYPE = 'MARKING_PERIOD_TYPE';
    static LOOKUP_CRISIS_TYPE = 'CALENDAR_CRISIS_TYPE';
    static LOOKUP_SCHOOL_CALENDAR_EVENT_TYPE = 'CALENDAR_EVENT_TYPE';
    static LOOKUP_STAFF_CREDENTIAL_TYPE = 'CREDENTIAL_TYPE';
    static LOOKUP_STAFF_VISA_TYPE = 'GENERAL_VISA_TYPE';
    static LOOKUP_SCHOOL_PUBLICATION_STATUS = 'PUBLICATION_STATUS';
    static LOOKUP_MATH_OPERATOR = 'MATH_OPERATOR';
    static LOOKUP_REPORT_OPERATOR = 'REPORT_OPERATOR';
    static LOOKUP_GRADE_SCALE_DEFINITION = 'GRADE_SCALE_DEFINITION';
    static LOOKUP_SEARCH_TYPE = 'SEARCH_ENTITY';
    static LOOKUP_ACCOMMODATION_TYPE = 'ACCOMMODATION_TYPE';
    static LOOKUP_ACCOMMODATION_CATEGORY = 'ACCOMMODATION_CATEGORY';
    static LOOKUP_PERSON_OCCUPATION_TYPE = 'PERSON_OCCUPATION_TYPE';
    static LOOKUP_GPA_CALC_BASED_ON = 'GPA_CALC_BASED_ON';
    static LOOKUP_GRADE_TYPE = 'GRADE_TYPE';
    /*School section*/
    static SESSION_SECTION_ID = 'markersSectionId';
    static SESSION_GRADE_LEVEL_ID = 'markersGradeLevelId';
    static SESSION_SCHOOL_SESSION_ID = 'markersSchoolSessionId';
    static SESSION_ROOM_ID = 'markersRoomId';
    static SESSION_DEPARTMENT_ID = 'markersDepartmentId'
    static SESSION_GRADE_SCALE_ID = 'markersDepartmentId'
    static SESSION_PERIOD_ID = 'markersPeriodId'
    static SESSION_ROLE_ID = 'markersRoleId'
    static SESSION_MARKING_PERIOD_ID = 'markersRoleId'
    static DURATION_IN_DAYS = 'durationInDays'
    static MINUTES_PER_DAY = 'minutesPerDay'
    /*Student section*/
    static SESSION_SECTION_ID = 'markersSectionId'
    /*Student section*/
    static LOOKUP_STUDENT_ETHNICITY = 'ETHNICITY';
    static LOOKUP_STUDENT_HISPANIC_LATINO_ETHNICITY = 'HISPANIC_LATINO_ETH_TYPE';
    static LOOKUP_STUDENT_HISPANIC_LATINO_RACE = 'HISPANIC_LATINO_RACE_TYPE';
    static STUDENT_HISPANIC_LATINO_RACE_TYPE_UNKNOWN = "17299";
    static LOOKUP_STUDENT_LANGUAGE = 'LANGUAGE';
    static LOOKUP_STUDENT_ROLLING_RETENTION = 'STUDENT_ROLLING_RETENTION';
    static LOOKUP_STUDENT_IDENTIFICATION_SYSTEM = 'STUDENT_IDENTIFICATION_SYSTEM';
    static LOOKUP_STUDENT_PUBLIC_SCHOOL_RESIDENTIAL_STATUS = 'PUBLIC_SCHOOL_RESIDENTIAL_STATUS';
    static LOOKUP_STUDENT_OTHER_BACKWARD_COMMUNITY = 'STUDENT_OTHER_BACKWARD_COMMUNITY';
    static LOOKUP_STUDENT_MILITARY_CONNECTION = 'STUDENT_MILITARY_CONNECTION';
    static LOOKUP_STUDENT_MILITARY_BRANCH = 'MILITARY_BRANCH';
    static LOOKUP_STUDENT_VERIFIABLE_INFO = 'STUDENT_VERIFIABLE_INFO';
    static LOOKUP_STUDENT_DOCUMENT_VERIFICATION_CERTIFICATE = 'DOCUMENT_VERIFICATION_CERTIFICATE';
    static LOOKUP_STUDENT_ISSUER_COUNTRY = 'STUDENT_ISSUER_COUNTRY';
    static LOOKUP_STUDENT_CERTIFIED_BY = 'STUDENT_CERTIFIED_BY';
    static LOOKUP_STUDENT_VERIFIED_BY = 'STUDENT_VERIFIED_BY';
    static LOOKUP_STUDENT_VISA_TYPE = 'STUDENT_VISA_TYPE';
    static LOOKUP_STUDENT_PERSON_RELATIONSHIP = 'PERSON_RELATIONSHIP';
    static LOOKUP_STUDENT_DISABILITY_TYPE = 'DISABILITY_TYPE';
    static LOOKUP_STUDENT_ACCOMMODATION_NEED_TYPE = 'ACCOMMODATION_NEED_TYPE';
    static LOOKUP_STUDENT_DISABILITY_CONDITION_TYPE = 'DISABILITY_CONDITION_TYPE';
    static LOOKUP_STUDENT_DISABILITY_CONDITION_NATURE = 'DISABILITY_CONDITION_NATURE';
    static LOOKUP_STUDENT_DETERMINED_BY = 'DISABILITY_DETERMINATION_SOURCE';
    static LOOKUP_STUDENT_IDEA_DISABILITY_TYPE = 'IDEA_DISABILITY_TYPE';
    static LOOKUP_SCHOOL_LUNCH_PROGRAM_TYPE = 'SCHOOL_LUNCH_PROGRAM_TYPE';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY = 'FOOD_SERVICE_ELIGIBILITY';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FREE = 'FOOD_SERVICE_ELIGIBILITY_TYPE_FREE';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FULL = 'FOOD_SERVICE_ELIGIBILITY_TYPE_FULL';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_REDUCED = 'FOOD_SERVICE_ELIGIBILITY_TYPE_REDUCED';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_OTHER = 'FOOD_SERVICE_ELIGIBILITY_TYPE_OTHER';
    static LOOKUP_STUDENT_ENROLLMENT_GIFTED_PROGRAM = 'ENROLLMENT_PROGRAM_GIFTED';
    static LOOKUP_STUDENT_ENROLLMENT_CODE = 'LOOKUP_STUDENT_ENROLLMENT_CODE';
    static LOOKUP_ENROLLMENT_ENTRY_TYPE = 'ENROLLMENT_ENTRY_TYPE';
    static LOOKUP_EXIT_WITHDRAWAL_STATUS = 'EXIT_WITHDRAWAL_STATUS';
    static LOOKUP_EXIT_ENROLLMENT_STATUS = 'ENROLLMENT_STATUS';
    static LOOKUP_EXIT_ROLLING_RETENTION = 'ROLLING_RETENTION_TYPE';
    static LOOKUP_EXIT_WITHDRAWAL_TYPE = 'EXIT_WITHDRAWAL_TYPE';
    static LOOKUP_PROMOTION_REASON = 'PROMOTION_REASON';
    static LOOKUP_NON_PROMOTION_REASON = 'NON_PROMOTION_REASON';
    static LOOKUP_STUDENT_REFERRAL_TYPE = 'STUDENT_REFERRAL_TYPE';
    static LOOKUP_STUDENT_REFERRAL_OUTCOME = 'STUDENT_REFERRAL_OUTCOME';
    static LOOKUP_STUDENT_VISION_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_HEARING_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_DENTAL_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_HEALTH_INSURANCE_COVERAGE = 'HEALTH_INSURANCE_COVERAGE';
    static LOOKUP_STUDENT_DENTAL_INSURANCE_COVERAGE = 'HEALTH_INSURANCE_COVERAGE';
    static LOOKUP_STUDENT_MEDICAL_ALERT_TYPE = 'MEDICAL_ALERT_TYPE';
    static LOOKUP_STUDENT_HEALTH_SCREENING_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_MULTIPLE_BIRTH = 'MULTIPLE_BIRTH';
    static LOOKUP_STUDENT_INSURANCE_TYPE = 'INSURANCE_TYPE';
    static LOOKUP_RELATIONSHIP = 'STUDENT_RELATIONSHIP';
    static LOOKUP_ADA_ELIGIBILITY_TYPE = 'ADA_ELIGIBILITY_TYPE';
    static LOOKUP_NAME_SUFFIX_TYPE = 'NAME_SUFFIX';
    static LOOKUP_IMMUNIZATION_ROUTE = 'IMMUNIZATION_ROUTE';
    static LOOKUP_IMMUNIZATION_STATUS = 'IMMUNIZATION_STATUS';
    static LOOKUP_INJECTION_SITE = 'INJECTION_SITE';
    /*Staff Section */
    static LOOKUP_STAFF_CERTIFICATE_ACCREDITATION_STATUS = 'CERTIFICATE_ACCREDITATION_STATUS';
    static LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_TYPE = "TEACHING_CREDENTIAL_TYPE";
    static LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_BASIS = "TEACHING_CREDENTIAL_BASIS";
    static LOOKUP_STAFF_CREDENTIAL_CERTIFICATE_SPONSOR_TYPE = "STAFF_CERT_SPONSOR_TYPE";
    static LOOKUP_STAFF_LEAVE_TYPE = "LEAVE_TYPE";
    static LOOKUP_STAFF_PERSONAL_DOCUMENT_VERIFICATION = 'DOCUMENT_VERIFICATION_CERTIFICATE';
    //static LOOKUP_STAFF_JOB_CATEGORY_SCHOOL_ROLE = "SCHOOL_ROLE";
    static LOOKUP_STAFF_CERTIFICATION_TYPE = "CERTIFICATION_TYPE";
    static LOOKUP_CERTIFICATE_VERIFICATION_METHOD = 'CERTIFICATE_VERIFICATION_METHOD';
    static LOOKUP_DOCUMENT_VERIFICATION_STATUS = 'DOCUMENT_VERIFICATION_STATUS';
    static LOOKUP_LEAVE_STATUS_TYPE = 'LEAVE_STATUS_TYPE';
    static MASS_IMMUNIZATION_INFO = "massImmunizationInfo"
    static LOOKUP_CERTIFICATE_TYPE = 'lookupCertificationType'
    //Course lookup
    static LOOKUP_COURSE_LEVEL_CHARACTERISTIC = 'COURSE_LEVEL_CHARACTERISTIC';
    static LOOKUP_COURSE_CREDIT_UNIT = 'COURSE_CREDIT_UNIT';
    static LOOKUP_COURSE_WBL_OPPORTUNITY_TYPE = 'COURSE_WBL_OPPORTUNITY_TYPE';
    static LOOKUP_ADITIONAL_CREDIT_TYPE = 'ADDITIONAL_CREDIT_TYPE';
    static LOOKUP_COURSE_GPA_APPLICABILITY = 'COURSE_GPA_APPLICABILITY';
    static LOOKUP_CURRICULUM_FRAMEWORK_TYPE = 'CURRICULUM_FRAMEWORK_TYPE';
    static LOOKUP_COURSE_CREDIT_TYPE_EARNED = 'COURSE_CREDIT_TYPE_EARNED';
    static LOOKUP_COURSE_CAREER_CLUSTER = 'COURSE_CAREER_CLUSTER';
    static LOOKUP_COURSE_BLND_LEARNING_MODEL_TYPE = 'COURSE_BLND_LEARNING_MODEL_TYPE';
    static LOOKUP_COURSE_EXAM_REQUIREMENT = 'COURSE_EXAM_REQUIREMENT';
    static LOOKUP_ADVANCED_PLACEMENT_COURSE_CODE = 'ADVANCED_PLACEMENT_COURSE_CODE';
    static LOOKUP_PERIOD_SCHEDULE_TYPE = 'PERIOD_SCHEDULE_TYPE';
    static LOOKUP_SINGLE_SEX_CLASS_STATUS = 'SINGLE_SEX_CLASS_STATUS';
    static LOOKUP_COURSE_SECTION_DELIVERY_MODE = 'COURSE_SECTION_DELIVERY_MODE';
    static LOOKUP_COURSE_SECTION_LEVEL_TYPE = 'COURSE_SECTION_LEVEL_TYPE';
    static LOOKUP_COURSE_TYPE = 'COURSE_TYPE';
    static LOOKUP_COURSE_SECTION_EXIT_TYPE = 'COURSE_SECTION_EXIT_TYPE';
    static LOOKUP_COURSE_SECTION_ENTRY_TYPE = 'COURSE_SECTION_ENTRY_TYPE';
    static LOOKUP_COURSE_SEQUENCE_TYPE = 'COURSE_SEQUENCE_TYPE';
    static LOOKUP_COURSE_APPROVAL_STATUS = 'APPROVAL_STATUS'
    // District 
    static COMPONENT_CONTROL_PANEL = 'controlpanel';
    static COMPONENT_DISTRICT_GET_DISTRICT_SCHOOL_PREVIOUS_SESSION_ACESSS = 'v1/allowadmintoeditprevioussessiondata/getbydistrictid?';
    static ADMIN_SCHL_PREV_SESSION_PERMISSION = 'adminSchlPrevSessionPermission';
    static CURRENT_SCHOOL_SESSION_END_DATE = 'currentSchoolSessionEndDate';
    // Attendance Section 
    static LOOKUP_ATTENDANCE_STATUS = 'ATTENDANCE_STATUS';
    static LOOKUP_PRESENT_ATTENDANCE_CATEGORY = 'PRESENT_ATTENDANCE_CATEGORY';
    static LOOKUP_ABSENT_ATTENDANCE_CATEGORY = 'ABSENT_ATTENDANCE_CATEGORY';
    static ATTENDANCE_STATUS = 'attendanceStatus';
    static EMAIL_SELECTED_TEACHER_ID = 'EmailSelectedTeacherId';
    static EMAIL_SELECTED_TEACHER = 'EmailSelectedTeacher';

    // Discipline Section 
    static LOOKUP_INCIDENT_TIME_TYPE = 'INCIDENT_TIME_TYPE';
    static LOOKUP_INCIDENT_BEHAVIOUR_TYPE = 'INCIDENT_BEHAVIOR_TYPE';
    static LOOKUP_INCIDENT_BEHAVIOUR = 'INCIDENT_BEHAVIOR';
    static LOOKUP_WEAPON_TYPE = 'WEAPON_TYPE';
    static LOOKUP_INJURY_TYPE = 'INJURY_TYPE';
    static LOOKUP_INCIDENT_LOCATION = 'INCIDENT_LOCATION';
    static LOOKUP_FIREARM_TYPE = 'FIREARM_TYPE';
    static LOOKUP_MULTIPLE_OFFENCE_TYPE = 'MULTIPLE_OFFENSE_TYPE';
    static LOOKUP_DISCIPLINE_REASON = 'DISCIPLINE_REASON';
    static LOOKUP_DISCIPLINARY_ACTION_TAKEN = 'DISCIPLINARY_ACTION_TAKEN';
    static LOOKUP_DISCIPLINE_LENGTH_DIFFERENCE = 'DISCIPLINE_LENGTH_DIFFERENCE';
    static LOOKUP_IDEA_INTERIM_REMOVAL = 'IDEA_INTERIM_REMOVAL';
    static LOOKUP_IDEA_INTERIM_REMOVAL_REASON = 'IDEA_INTERIM_REMOVAL_REASON';
    static LOOKUP_FIREARM_DISCIPLINE_METHOD = 'FIREARM_DISCIPLINE_METHOD';
    static LOOKUP_IDEA_FIREARM_DISCIPLINE_METHOD = 'IDEA_FIREARM_DISCIPLINE_METHOD';
    static LOOKUP_DM_FOR_DIABETIC_CHILDREN = 'DM_FOR_DIABETIC_CHILDREN';
    static LOOKUP_INCIDENT_PERSON_ROLE = 'INCIDENT_PERSON_ROLE';
    static LOOKUP_INCIDENT_PERSON_TYPE = 'INCIDENT_PERSON_TYPE';
    static INCIDENT_ID = 'incidentId';
    //Assessment Section
    static LOOKUP_ASSESSMENT_ASSET_TYPE = 'ASSESSMENT_ASSET_TYPE';
    static LOOKUP_PUBLICATION_STATUS = 'PUBLICATION_STATUS';
    static LOOKUP_ASSESSMENT_PURPOSE = 'ASSESSMENT_PURPOSE';
    static LOOKUP_ASSESSMENT_TYPE = 'ASSESSMENT_TYPE';
    static LOOKUP_ASSESSMENT_TYPE_WITH_DISABILITY = 'ASSESSMENT_TYPE_WITH_DISABILITIES';
    static LOOKUP_ASSESSMENT_REGISTRATION_COMPLETION_STATUS = 'ASMT_REGISTRATION_COMPLETION_STATUS';
    static LOOKUP_ASSESSMENT_PARTICIPATION = 'ASSESSMENT_PARTICIPATION';
    static LOOKUP_ASSESSMENT_REASON_NOT_TESTED = 'ASSESSMENT_REASON_NOT_TESTED';
    static LOOKUP_ASSESSMENT_REASON_NOT_COMPLETED = 'ASSESSMENT_REASON_NOT_COMPLETED';
    static LOOKUP_ASSESSMENT_SESSION_TYPE = 'ASSESSMENT_SESSION_TYPE';
    static LOOKUP_ASSESSMENT_REPORTING_METHOD = 'ASSESSMENT_REPORTING_METHOD';
    static LOOKUP_ASSESSMENT_FORM_SEC_IDENTIFICATION_SYSTEM = 'ASMT_FORM_SEC_IDENTIFICATION_SYSTEM';
    static LOOKUP_SCORE_METRIC_TYPE = 'SCORE_METRIC_TYPE';
    static LOOKUP_ASSESSMENT_OUTCOME = 'ASSESSMENT_OUTCOME';
    static LOOKUP_ASSESSMENT_RESULT_SCORE_TYPE = 'ASSESSMENT_RESULT_SCORE_TYPE';
    static SESSION_ASSESSMENT_ADMINID = 'ASSESSMENT_ADMIN_ID';
    static LOOKUP_ASSIGNMENT_AGGREGATION = 'ASSIGNMENT_AGGREGATION_TYPE';
    static LOOKUP_ASSIGNMENT_GRADING_TYPE = 'ASSIGNMENT_GRADING_TYPE';
    // Announcement
    static LOOKUP_ANNOUNCEMENT_TYPE = 'ANNOUCEMENT_TYPE';
    // Transport
    static LOOKUP_VEHICLE_TYPE = 'VEHICLE_TYPE';
    static LOOKUP_OWNERSHIP_TYPE = 'OWNERSHIP_TYPE';
    static LOOKUP_SEAT_TYPE = 'SEAT_TYPE';
    static LOOKUP_TRANSPORTATION_JOB_TYPE = 'TRANSPORTATION_JOB_TYPE';
    static LOOKUP_TRANSPORTATION_STAFF_ROLE_TYPE = 'TRANSPORTATION_STAFF_ROLE';
    static LOOKUP_TRANSPORTATION_REQUEST_TYPE = 'TRANSPORTATION_REQUEST_TYPE';
    static LOOKUP_TRANSPORTATION_VEHICLE_LOG_TYPE = 'TRANSPORTATION_VEHICLE_LOG_TYPE';
    static LOOKUP_TRANSPORTATION_VEHICLE_IMAGE_TYPE = 'TRANSPORTATION_VEH_IMAGE_TYPE';
    static TRANSPORTATION_REQUEST_STATUS_DECLINE_COLOUR = 'red';
    static TEXT_DECORATION_NONE = 'none';
    static LOOKUP_TRANSPORT_STAFF_DRIVER_ID = 2709;
    static LOOKUP_TRANSPORT_STAFF_CONDUCTOR_ID = 2710;
    static TRANSPORTATION_REQUEST_STATUS_DECLINE = 22203;
    static TRANSPORTATION_REQUEST_STATUS_PENDING = 22201;
    static TRANSPORTATION_REQUEST_STATUS_APPROVED = 22202;
    static LOOKUP_TRANSPORTATION_REQUEST_TYPE_BOTH = 22101;
    static LOOKUP_TRANSPORTATION_REQUEST_TYPE_TO_SCHOOL = 22102;
    static LOOKUP_TRANSPORTATION_REQUEST_TYPE_FROM_SCHOOL = 22103;
    static LOOKUP_FUEL_TYPE = "FUEL_TYPE";
    static VEHICLE_IDENTIFICATION_NUMBER_MIN_LENGTH = 21;
    static MANUFACTURER_YEAR_MIN_LENGTH = 4;
    static LICENSE_PLATE_NUMBER_MIN_LENGTH = 7;
    //Application level Constant
    static BUTTON_SAVE = 'saveBtn'
    static IS_VIRTUAL = 'isVirtual';
    static IS_GRADED = 'isGraded';
    static HAS_EXAM = 'hasExam';
    static IS_ACTIVE = 'isActive';
    static IS_SCHEDULING_TERM = 'isSchedulingTerm';
    static MPGRADETYPE = 'mpGradeType';
    static MPGRADEOPTION = 'mpGradeOption';
    static IS_ROUNDUP = 'isRoundUp';
    static IS_DEFAULT = 'isDefault';
    static IS_ALL_DAY = 'isAllDayLong';
    static IS_VISIBLE_STUDENT = 'isVisibleToStudents';
    static IS_VISIBLE_STAFF = 'isVisibleToStaff';
    static WEEKDAYS = 'weekdays';
    static IS_ACRIDATED = 'accredited';
    static ACRIDATED = 'isAccredited';
    static IS_EXCLUDE_FROM_CALCULATION = 'isExcludeFromCalculation';
    static IS_ALLOWEDFORSCHOOL = 'isAllowedForSchool';
    static IS_ALLOWATTENDANCE = 'allowAttendance';
    static IS_IGNORESCHEDULING = 'ignoreScheduling';
    static IS_DELETED = 'isDeleted';
    static IS_VISIBLE = 'isVisibleToOtherSchools';
    static IS_ACTIVITY = 'isActivity';
    static IS_PUBLISHED = 'isPublished';
    static IS_AFFECT = 'isAffect';
    static AM = 'AM';
    static PM = 'PM';
    static SUCCESS_NOTIFY_TYPE = 1;
    static ERROR_NOTIFY_TYPE = 2;
    static IS_CLOSED = 'isClosed';
    static SORTORDER = 'sortOrder';
    static DFAULT_TIME = '0001-01-01T00:00:00';
    static UNPUBLISHED_COUNT = 0;
    static BASE_APP_PATH = 'https://localhost:5001';
    static TRANSPORT_APP_PATH = 'https://localhost:5006';
    static BILLING_APP_PATH = 'https://localhost:5004';
    static IS_LMS = 'lms';
    static IS_TRANSPORT = 'transport';
    static IS_BILLING = 'billing';
    static IS_HOME = 'home';
    //LMS  
    static IS_LMS_FLAG = 'isLmsFlag';
    static LMS = 'LMS';
    //validate subscription
    static VALIDATE_SUBSCRIPTION_BY_MODULE_KEY = 'v1/subscription/validatesubscription?moduleKey=';
    //Phone relate constants
    static PHONE_NUMBER_MIN_LENGTH = 11;
    static HOME_NUMBER = 903;
    static WORK_NUMBER = 901;
    static MOBILE_NUMBER = 902;
    //School fields related Constant
    static IS_SEARCHABLE = 'searchable';
    static IS_ACADEMIC = 'isAcademic';
    static IS_REQUIRED = 'isRequired';
    static IS_MANDATORY = 'isMandatory';
    static IS_HIDE = 'hide';
    static IS_NUMERIC = 'numeric';
    static IS_VARCHAR = 'varchar';
    static IS_SPL_CHAR = 'specialCharacter';
    static IS_MAX_LENGTH = 'maxLength';
    static IS_DATE = 'date';
    static GEN_MENUCODE = 'SCHL_ADD_GEN';
    static ADMIN_MENUCODE = 'SCHL_ADD_ADM';
    static ACC_MENUCODE = 'SCHL_ADD_ACC';
    static DESIG_MENUCODE = 'SCHL_ADD_DES';
    static IDEN_MENUCODE = 'ADD_SCHOOL_IDENTIFIER';
    static STUGEN_MENUCODE = 'STUD_ADD_GEN';
    static STUIDEN_MENUCODE = 'STUD_ADD_IDE';
    static STUMISC_MENUCODE = 'STUD_ADD_MIS';
    static STUREF_MENUCODE = 'STUD_ADD_REF';
    static STUVISA_MENUCODE = 'STUD_ADD_VIS';
    static STUENROLL_MENUCODE = 'STUD_ADD_ENR';
    static STUDIS_MENUCODE = 'STUD_ADD_DIS';
    static SCHOOLADD_MENUCODE = 'SCHOOL_ADD_ADDRESS';
    static STUGUA_MENUCODE = 'STUD_ADD_GUA';
    static STUHEALTH_MENUCODE = 'STUD_ADD_HEA';
    static STUBIRTH_MENUCODE = 'STUD_ADD_BIR';
    static STUIMMU_MENUCODE = 'STUD_ADD_IMM';
    static STUCOHO_MENUCODE = 'STUD_ADD_COH';
    static STUELIGI_MENUCODE = 'STUD_ADD_ELI';
    static STUGIFT_MENUCODE = 'STUD_ADD_GIF';
    static STFDEM_MENUCODE = 'STAF_ADD_DEM';
    static STFEMP_MENUCODE = 'STAF_ADD_EMP';
    static STFROLE_MENUCODE = 'STAF_ADD_ROL';
    static STFCONT_MENUCODE = 'STAF_ADD_CON';
    static STFQUALI_MENUCODE = 'STAF_ADD_QUA';
    static STFDOCU_MENUCODE = 'STAF_ADD_DOC';
    static STFVISA_MENUCODE = 'STAF_ADD_VIS';
    static STFTEACH_MENUCODE = 'STAF_ADD_TEA';
    static STFCRED_MENUCODE = 'STAF_ADD_CRE';
    static STFPER_MENUCODE = 'STAF_ADD_PER';
    static CUSTOMFIELD_ADD = 'add';
    static CUSTOMFIELD_VIEW = 'view';
    static TEXTBOX = 301;
    static DROPDOWN = 302;
    static CHECKBOX = 303;
    static RADIO = 304;
    static TAREA = 305;
    static DATEFIELD = 306;
    static DATETIME = 307;
    static MULTI = 308;
    static STRING = 10001;
    static INT = 10002;
    static BIGINT = 10003;
    static DECIMAL = 10004;
    static DATE = 10005;
    static DATETM = 10006;
    static BOOLEAN = 10007;
    static CHAR = 10008;
    static DEFAULT_PUBLICATION_STATUS = 9502;
    static PUBLISHED_PUBLICATION_STATUS = 9503;
    static ADOPTED_PUBLICATION_STATUS = 9501;
    //StateReporting LookUp
    static COMPONENT_SR_LOOKUP_FIELDTYPE_DATEFIELD = 306;
    static COMPONENT_SR_LOOKUP_DATATYPE_STRING = 10001;
    static COMPONENT_SR_LOOKUP_DATATYPE_INTEGER = 10002;
    static COMPONENT_SR_LOOKUP_DATATYPE_BIGINTEGER = 10003;
    static COMPONENT_SR_LOOKUP_DATATYPE_DECIMAL = 10004;
    static COMPONENT_SR_LOOKUP_DATATYPE_DATE = 10005;
    static COMPONENT_SR_LOOKUP_DATATYPE_DATETIME = 10006;
    static COMPONENT_SR_LOOKUP_DATATYPE_BOOL = 10007;
    static fieldName = 'fieldName';
    static CUSTOMFIELD = 'FIELD';
    static CUSTOMFIELDGROUP = 'FIELDGROUP';
    static IS_SCHEDULE = 'SCHEDULE';
    //Department related Constant
    static IS_DELETED = 'deleted';
    static IS_CREATE_IN_ALL_SCHOOLS = 'createInAllSchools';
    static IS_REPORTER = 'isreporter';
    //Datatable id related constant
    static GRADELEVEL_DATATABLE = 'gradeLevelsTable';
    static SECTION_DATATABLE = 'sectionTable';
    static SESSION_DATATABLE = 'sessionTable';
    static ROOM_DATATABLE = 'roomTable';
    static PERIOD_DATATABLE = 'periodTable';
    static ROLE_DATATABLE = 'roleTable';
    static DEPARTMENT_DATATABLE = 'departmentTable';
    static STUDENT_LIST_DATATABLE = 'studentlist';
    static ACTIVITY_GROUP_DATATABLE = 'activityGroup';
    static ACTIVITY_GROUP_ADD_DATATABLE = 'activitygroupadd';
    static STAFF_LIST_DATATABLE = 'staffList';
    static SCHOOL_CUSTOM_CATEGORY = 'SCHL_FIELD';
    static STUDENT_CUSTOM_CATEGORY = 'STUD_FIELD';
    static STAFF_CUSTOM_CATEGORY = 'STAF_FIELD';
    static TABLE_RESET_COUNT = 10;
    //Staff related constant
    static IS_EMERGENCY_CONTACT = 'isEmergencyContact';
    static LOOKUP_CALENDAR_EVENT_TYPE = 'lookupCalendarEventType';
    static IS_ALL_DAY_LONG = 'isAllDayLong';
    static HOME_ADDRESS = 'Home Address';
    static OTHER_ADDRESS = 'Other Address';
    //Student related constant
    static ENROLLMENT_STATUS_NO_SHOW = 'No Show';
    static DEFAULT_GENDER = "M";
    //Person related constant
    static IS_HOME_PHONE = 'Home Phone';
    static IS_MOBILE_PHONE = 'Mobile Phone';
    static IS_WORK_PHONE = 'Work Phone';
    static IS_FAX = 'Fax';
    //Calendar Crisis related constant
    static IS_EFFECT_DAY_ACTIVITY = 'isInterruptDayActivity';
    //Staff Leave related constant
    static IS_PARTIAL_DAY_LEAVE = 'isPartialDayLeave';
    static IS_NOTIFIED = 'isNotified';
    //Course Manager
    static COURSEMANAGER_SUBJECT = 'subject';
    static COURSEMANAGER_COURSE = 'course';
    static COURSEMANAGER_COURSESECTION = 'courseSection';
    static DELETELEVEL = 'level';
    static IS_EXAM = 'isExam';
    static IS_ASYNCHRONOUS = 'isAsynchronous';
    static INSTRUCTIONAL_MINUTES = 'instructionalMinutes'
    static MAXIMUM_REPETABILITY = 'maximumRepeatibility';
    static COURSE_WEIGHT = 'courseWeight';
    static CARNAGE_UNIT_CREDIT = 'carnegieUnitCredit';
    static CREDIT_VALUE = 'creditValue';
    static COURSES_ID = 'markersCoursesId';
    static COURSES_SUBJECT_ID = 'markersCoursesSubjectId'
    static COURSE_SUBJECT_ID = 'markersSubjectId';
    static COURSE_ID = 'markersCourseId';
    static COURSE_SECTION_ID = 'markersCourseSectionId';
    static COURSE_SUBJECT_TITLE = 'markersSubjectTitle';
    //Discipline
    static IS_PENDING = 'Pending';
    static IS_APPROVED = 'Approved';
    static IS_CANCELLED = 'Cancelled';
    static PERPETRATOR = 'perpetrator';
    static VICTIM = 'victim';
    //User Role
    static SCHOOL_ROLE_DIST_ADMIN = 802;
    static SCHOOL_ROLE_ADMIN = 2701;
    static SCHOOL_ROLE_TEACHER = 2702;
    static SCHOOL_ROLE_PARENT = 2703;
    static SCHOOL_ROLE_STUDENT = 2704;
    static SCHOOL_ROLE_COUNCILLOR = 2705;
    static SCHOOL_ROLE_ACCOUNTANT = 2706;
    static SCHOOL_ROLE_HR = 2707;
    static SCHOOL_ROLE_CUSTOM = 2799;
    static STAFF_ROLE_DRIVER = 2709;
    static STAFF_ROLE_CONDUCTOR = 2710;

    //Transcript
    static IS_EXCLUDE = 'isExcludeMassDelete';
    static SYSTEM = 18501;
    static MANUAL_OUT = 18502;
    static MANUAL_IN = 18503;

    //State Reporting Entity Title

    static ORG_LEA_SR = 'Local Education Agency - State Reporting Page';
    static ORG_SCH_SR = 'School - State Reporting Page';
    static ORG_RSD_SR = 'Residential School - State Reporting Page';
    static ORG_PRD_PI = 'Period - Period Info Page';
    static ORG_CRS_CI = 'Course - Course Info Page';
    static ORG_PRG_PI = 'Program - Program Info Page';
    static SSN_PRD_SR = 'Session - State Reporting Page';
    //State Reporting Entity Key
    static STATE_LEA = 'ORG_LEA_SR';
    static STATE_SCH = 'ORG_SCH_SR';
    static STATE_RSD = 'ORG_RSD_SR';
    static STATE_PRD = 'ORG_PRD_PI';
    static STATE_CRS = 'ORG_CRS_CI';
    static STATE_PRG = 'ORG_PRG_PI';
    static STATE_PRD = 'SSN_PRD_SR';
    static STATE_TRA_MR_VH = 'TRA_MR_VH';

    //RegExp  
    
    static ALPHANUMERIC_CHARACTER = /^[a-zA-Z0-9]+$/; // Accept only alphanumeric characters
    static FOUR_DIGIT_YEAR = /^(19|20)\d{2}$/;
    static SPECIAL_ALPHA_CHAR = /^.{ 1, 50 }$/;
    static SPECIAL_CHAR_ID = /^(?!\s)[a-z\d\-.:/\s]+$/i;  //special characters included -.:/
    static SPECIAL_CHAR_TEXTAREA = /^(?!\s)[a-z\d\,'-.:/\s]+$/i;  //special characters included ,'-.:/
    static SPECIAL_CHAR_NAME = /^(?!\s)[a-z\d\,.-\s]+$/i;  //special characters included ,.-
    static SPECIAL_CHAR_DESCRIPTION = /^(?!\s)[a-z\d\,'-./\s]+$/i;  //special characters included ,.-/'
    static SPECIAL_CHAR_IDENTIFICATION_CODE = /^(?!\s)[a-z\d\'-/\s]+$/i;  //special characters included -/'
    static ISSUER_NAME = /^(?!\s)[a-zA-Z\s]+$/i; //only alphabates with space
    static IDENTIFIER = /^[a-zA-Z\d]+$/i;
    static SPECIAL_TEXTAREA_ID = /^(?!\s)[a-z\d\-.:/,&\s]+$/i;
    static GRADE_REGEX = /^\d{0,4}$/;
    static WEBSITE_REGEX = /^(https ?: \/\/)?(www\.)?([a-zA-Z\d\/\\&.:\s]+(-?[a-zA-Z\d\/\\&.:\s])*\.)+[\w]{2,}(\/\S*)?$/;
    static SCHOOL_NAME_REGEX = /^(?!\s)[a-zA-Z\d\'^\s]+$/i;
    static ADDRESS_SCHOOL = /^(?!\s)[a-zA-Z\d\/,&.:-\s]+$/i;
    static ADDRESS_CITY_STATE_COUNTRY = /^(?!\s)[A-Za-z ]+$/     //allows only alphabets and space
    static POSTAL_CODE_ZIP = /^(?!\s)[0-9-\s]*[0-9-\s][0-9-\s]*$/
    static POSTAL_CODE_ZIP_ADDRESS = /^(?!\s)[0-9-/\s]*[0-9-/\s][0-9-/\s]*$/
    static PHONE_NUMBER_FORMAT = /^(?!\s)[0-9-()\s]*[0-9-()\s][0-9-()\s]*$/
    static AREA_CODE = /^(?!\s)[0-9+-\s]*[0-9+-\s][0-9+-\s]*$/;
    static PHONE_EXT = /^[0-9]+$/;   //only numbers
    static EMAIL = /^([a-zA-Z\d\.-_@$]+(-?[a-zA-Z\d\.-_@$])*\.)+[\w]{2,}(\/\S*)?$/
    static DESIGNATION = /^(?!\s)[a-zA-Z\'.\s]+$/i;
    static GROUP_TITLE = /^(?!\s)[a-zA-Z\d\-&:/s]+$/i;
    static GROUP_TITLE2 = /^(?!\s)[0-9a-zA-Z\s\-:&'/]+$/i;   //accepts alphanumeric and special characters &:/- and space 
    static FIELD_NAME_LABEL_VALIDATION_MESSAGE = /^(?!\s)[a-z\d\-.%#&\s]+$/i;
    static CALENDAR_TITLE = /^(?!\s)[a-z\d\.""\-'#&*()\/+\s]+$/i;
    static DESCRIPTION = /^(?!\s)[a-z\d\-.'&*""\s]+$/i;
    static EVENT_TITLE = /^(?!\s)[a-z\d\.""\-'&*()\/+\s]+$/i;
    static DESCRIPTION_EVENT = /^(?!\s)[a-z\d\.""\-'&*()\s]+$/i;
    static PERIOD_TITLE = /^(?!\s)[a-z\d\-.\s]+$/i;    //accepts charecters -.
    static PERIOD_CODE = /^(?!\s)[a-z\d\-./\s]+$/i;
    static EXIT_CODE = /^(?!\s)[a-z\d\-/.&:-\s]+$/i;
    static GRADE_CODE = /^(?!\s)[a-z\d\-'#/\s]+$/i;
    static SECTION_TITLE = /^(?!\s)[a-z\d\.-\s]+$/i;
    static SECTION_CODE = /^(?!\s)[a-z\d\-.+&\s]+$/i;
    static SECTION_DESCRIPTION = /^(?!\s)[a-z\d\.,-/'\s]+$/i;
    static SESSION_CODE = /^(?!\s)[a-z\d\-.""'&/\s]+$/i;
    static ROLLOVER_SESSION_CODE = /^(?!\s)[a-z\d\-/&/\s]+$/i;
    static SESSION_DESCRIPTION = /^(?!\s)[a-z\d\-.""'&/\s]+$/i; //Follow this pattern if there are many charecters need to be allowed
    static PREMISES_TITLE = /^(?!\s)[a-z\d\-.'#&\s]+$/i;
    static ROOMS_DESCRIPTION = /^(?!\s)[a-z\d\-.'&()%$\s]+$/i;
    static CALENDAR_CRISIS_DESCRIPTION = /^(?!\s)[a-z\d\-.%#&\s]+$/i;
    static DEPARTMENT_TITLE = /^(?!\s)[a-z\d\-./&,#\s]+$/i;
    static DEPARTMENT_DESCRIPTION = /^(?!\s)[a-z\d\-./,&#\s]+$/i;
    static HOLIDAY_MANAGEMENT_TITLE_DESCRIPTION = /^(?!\s)[a-z\d\-&/:\s]+$/i;
    static PREMISE_ADDRESS = /^(?!\s)[a-z\d\-.:,&\s]+$/i;
    static PHONE_NUMBER_ADD_PERSON = /^(?!\s)[0-9+-\s]*[0-9+-\s][0-9+-\s]*$/
    static NAME_SUFFIX = /^(?!\s)[a-zA-Z ]*$/;   //allows alphabets and space only
    static STUDENT_GENERAL_INFO = /^(?!\s)[a-z\d\'^\s]+$/i;
    static STUDENT_CODE = /^(?!\s)[a-z\d\-/\s]+$/i;
    static STUDENT_CITY_STATE = /^(?!\s)[a-zA-Z ]*$/;
    static SYSTEM_NAME = /^(?!\s)[a-z\.,-\s]+$/i;
    static IDENTIFICATION_CODE = /^(?!\s)[a-z\d\-/\s]+$/i;
    static SCHEDULE_CAST_NAME = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static TRIBAL_CAST_NAME = /^(?!\s)[a-z\d\-.,'\s]+$/i;
    static REFERRAL_REGEX = /^(?!\s)[a-z\d\-.,'\s]+$/i;
    static MARKING_PERIOD_DESCRIPTION = /^(?!\s)[a-z\d\-.:&\s]+$/i;
    static INSURANCE_NAME = /^(?!\s)[a-z\d\-.&/\s]+$/i;
    static ALLERGIC_DRUG_DETAILS = /^(?!\s)[a-z\d\-.:&',/\s]+$/i;
    static DOCUMENT_NAME = /^(?!\s)[a-z\d\-.:/\s]+$/i;
    static IMMUNIZATION_NAME = /^(?!\s)[a-z\d\-.'\s]+$/i;
    static COHORT_DESCRIPTION = /^(?!\s)[a-z\d\-.,/'\s]+$/i;
    static DOCTORS_COMMENTS = /^(?!\s)[a-z\d\-.,/'\s]+$/i;
    static UNION_MEMBERSHIP_NAME = /^(?!\s)[a-z\d\-.,&\s]+$/i;
    static CERTIFICATE_TITLE = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static INSTITUITION_NAME = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static ISSUER_NAME_PERSON = /^(?!\s)[a-z\-.'\s]+$/i;
    static DOCUMENT_IDENTIFICATION_CODE = /^(?!\s)[a-z\d\-'/\s]+$/i;
    static CERTIFICATE_NUMBER = /^(?!\s)[a-z\-./\s]+$/i;
    static JOB_TITLE = /^(?!\s)[a-z\d\-'/\s]+$/i;
    static JOB_DESCRIPTION = /^(?!\s)[a-z\d\-.,#&/\s]+$/i;
    static LEAVE_DESCRIPTION = /^(?!\s)[a-z\d\-.,'&/\s]+$/i;
    static GRADE_SCALE_TITLE = /^(?!\s)[a-z\d\-:&/\s]+$/i;
    static GRADE_SCALE_DESCRIPTION = /^(?!\s)[a-z\d\-:,%<>=&/\s]+$/i;
    static EMPLOYEE_CODE = /^(?!\s)[a-z\d\-./\s]+$/i;
    static LICENCE_NO = /^(?!\s)[a-z\d\-./\s]+$/i;
    static ADDRESS_COUNTY_REGEX = /^(?!\s)[A-Za-z ]+$/   //matches alphabets and space only
    static SUBJECT_TITLE = /^(?!\s)[a-z\d\-:&'/\s]+$/i;
    static SUBJECT_CODE = /^(?!\s)[a-z\d\-:&'_/\s]+$/i;
    static SUBJECT_DESCRIPTION = /^(?!\s)[a-z\d\-.&%#/\s]+$/i;
    static CERIFICATE_DESCRIPTION = /^(?!\s)[a-z\d\-.&#\s]+$/i;
    static FUNDING_PROGRAM = /^(?!\s)[a-z\d\-&'\s]+$/i;
    static ASSESSMENT_CODE = /^(?!\s)[a-z\d\-/'\s]+$/i;
    static ASSESTS_TITLE = /^(?!\s)[a-z\d\-'&/\s]+$/i;
    static SEA_LEA_ID = /^(?!\s)[1-9-]*[0-9-][0-9-]*$/;
    static EVENT_ATENDANCE = /^(?!\s)[a-z\d\,&-./\s]+$/i;  // Special Characters Included here are '&, , , ., -, /,;
    static DECIMAL_VALUE = /^(?!^0*$)(?!^0*\.0*$)^\d{1,5}(\.\d{1,2})?$/; // Check a Decimal value Upto (5,6)
    static FLOAT_VAL = /^[0-9]*\.?[0-9]*$/; // Allow only number and single dot
    static DECIMAL_REGEX = /^[1-9]\d*(\.\d+)?$/i;    //matches decimals and numbers
    static PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[!@#^&*])[a-zA-Z0-9!@#^&*]{3,20}$/;
    static INCIDENT_CODE_DESCRIPTION = /^(?!\s)[a-z\d\-.,;&/\s]+$/i;
    static LATE_REASON = /^(?!\s)[a-z\d\-.,&'/;\s]+$/i;
    static PRECISION_NINE_WITH_SCALE_THREE = /^\d{1,9}(\.\d{1,3})?$/       //Matches numeric digits with length 9 and scale of 3
    static OCCUPATION_TITLE_DESIGNATION = /^(?!\s)[a-z\d\-'\s]+$/i;
    static MONTHLY_INCOME = /^(?!\s)[0-9-.,\s]*[0-9-.,\s][0-9-.,\s]*$/
    static PRECISION_FIVE_WITH_SCALE_TWO = /^\d{0,5}(\.\d{1,2})?$/   //Matches numeric digits with length 5 and scale of 2
    static MIN_VALUE = /^[1-9]+$/; //only numbers 1 to 9
    static STAFF_CONTACT_RESTRICTION_NOTES = /^(?!\s)[a-z\d\-./&@'\s]+$/i;
    static ASSIGNMENT_TITLE = /^(?!\s)[0-9a-zA-Z\s\-:&'/]+$/i; //special characters included a-z, A-Z, 0-9, space, -/:'&
    static DESCRIPTION2 = /^(?!\s)[0-9a-zA-Z\s\-.:&/]+$/i; //special characters included a-z, A-Z, 0-9, space, -/:.&
    static MARKING_PERIOD_EXAM_CODE = /^(?!\s)[a-z\d\-:/#@\s]+$/i;
    static TRACK_ID = /^(?!\s)[0-9a-zA-Z\s\:']+$/i;   //accepts alphanumeric and special characters : and space 
    static ROUTE_FIELD = /^(?!\s)[0-9a-zA-Z\s\-:./]+$/i; //special characters included a-z, A-Z, 0-9, space, -/:.
    //File Types
    static FILE_TYPE_IMAGE = 'jpeg|jpg|png';
    static FILE_TYPE_IMAGE_MAXSIZE = 2000000;
    static FILE_TYPE_IMAGE_MINSIZE = 20000;
    static FILE_TYPE_DOCS = 'jpeg|jpg|png|pdf';
    static FILE_TYPE_DOCS_MAXSIZE = 2000000;
    static FILE_TYPE_DOCS_MINSIZE = 1024;
    static FILE_TYPE_EXCEL = 'xls';
    static FILE_TYPE_PDF = 'pdf';
    static FILE_TYPE_QUALIFICATION_CERTIFICATE = 'docx|jpg|png|doc|pdf|jpeg|pptx|ppt|xls|xlsx';
    static FILE_TYPE_DOCS_COPY = 'doc|docx|docs|pdf';
    static FILE_TYPE_ALL_DOCS = 'doc|docx|docs|pdf|pptx|ppt|xls|xlsx|odt';
    static FILE_TYPE_ONLY_DOCS = 'doc|docx|docs';
    static FILE_TYPE_ALL_DOCS_EMAIL = 'doc|docx|pdf|xlsx|png|jpg';
    static FILE_TYPE_PERSONAL_DOCUMENT = 'docx|jpg|png|doc|pdf|jpeg|xlsx|xls'
    static FILE_TYPE_ASSESSMENT_ASSESTS = 'docx|doc|pdf|xlsx'
    static FILE_DEFAULT_ID = 173;
    static FILE_LOCATION_TYPE = 2101;
    static FILE_LOCATION_TYPE2 = 2301;
    //Reset store
    static COMMON_STORE_RESET = 'COMMON_STORE_STATE_RESET';
    static STUDENT_STORE_RESET = 'STUDENT_STORE_STATE_RESET';
    static STAFF_STORE_RESET = 'STAFF_STORE_STATE_RESET';
    static SCHOOL_STORE_RESET = 'SCHOOL_STORE_STATE_RESET';
    static COURSE_STORE_RESET = 'COURSE_STORE_STATE_RESET';
    static SCHEDULE_STORE_RESET = 'SCHEDULE_STORE_STATE_RESET';
    static REPORT_STORE_RESET = 'REPORT_STORE_STATE_RESET';
    static ATTENDANCE_STORE_RESET = 'ATTENDANCE_STORE_STATE_RESET';
    static DISCIPLINE_STORE_RESET = 'DISCIPLINE_STORE_RESET';
    static ASSESSMENT_STORE_RESET = 'ASSESSMENT_STORE_RESET';
    static LOGIN_STORE_RESET = 'LOGIN_STORE_RESET';
    static SYSTEM_STORE_RESET = 'SYSTEM_STORE_RESET';
    //DATEPICKER
    static END_DATE = "-1y";
    static BIRTH_DATE = "-14y";
    static END_DATE_AWARD = new Date();
    static AWARD_DATE_NAME = 'awardDate';
    //DISCIPLINE
    static REPORTED_TO_LAW_ENFORCEMENT = "isReportedToLawEnforcement";
    static DISABILITY_MANIFASTATION = "isDisabilityManifestation";
    static IS_FULL_YEAR_EXPULSION = "isFullYearExpulsion";
    static IS_EXPULSION_SHORTED = "isExpulsionShorted";
    static IS_ZERO_TOLLERANCE_RELATED = "isZeroTollerenceRelated";
    static IS_ES_AVAILABLE_AFTER_REMOVAL = "isESAvailableAfterEemoval";
    static IS_IEP_PLACEMENT_MEETING = "isIEPPlacementMeeting";
    static DISCIPLINARY_ACTION_DURATION = "disciplinaryActionDuration";
    //ATTENDANCE
    static ATTN_PRESENT = 10101;
    static ATTN_EX_ABS = 10102;
    static ATTN_UNEX_ABS = 10103;
    static ATTN_LATE = 10104;
    static ATTN_FVMN_LATE = 10105;
    static ATTN_TARDY = 10106;
    static ATTN_ER_DEPT = 10107;
    //CUSTOM FIELD
    static ADOPTED_VALUE = 9501;
    static DRAFT_VALUE = 9502;
    static PUBLISHED_VALUE = 9503;
    //ASSESSMENT
    static INCLUDE_STUDENT_CODE = "includeStudentCode";
    static INCLUDE_DISTRICT_CODE = "includeDistrictCode";
    static INCLUDE_ENROLMENT_ID = "includeEnrolmentId";
    static INCLUDE_GRADE_LEVEL = "includeGradeLevel";
    static INCLUDE_STUDENT_PHOTO = "includeStudentPhoto";
    static INCLUDE_IDENTIFICATION_CODE = "includeIdentificationCode";
    static INCLUDE_ETHNICITY = "includeEthnicity";
    static INCLUDE_DATE_OF_BIRTH = "includeDateOfBirth";
    static SHOW_LETTER_GRADE = "showLetterGrade";
    static SHOW_PERCENTAGE = "showPercentage";
    static DISPLAY_BASE_GRADING_SCALE = "displayBaseGradingScale";
    static GRADE_PERCENTAGE = "gradePercentage";
    static STUDENT_INFO = "studentInfo";
    static IS_STUDENT_INFO = "isStudentInfo";
    static IS_STUDENT_CODE = "isStudentCode";
    static IS_INCLUDE_STUDENT_PHOTO = "isIncludeStudentPhoto";
    static IS_DISTRICT_CODE = "isDistrictCode";
    static IS_IDENTIFICATION_CODE = "isIdentificationCode";
    static IS_ENROLMENT_ID = "isEnrolmentID";
    static IS_ETHNICITY = "isEthnicity";
    static IS_GRADE_LEVEL = "isGradeLevel";
    static IS_DOB = "isDOB";
    static IS_GRADE_PERCENTAGE = "isGradePercentge";
    static IS_SHOW_LETTER_GRADE = "isShowLetterGrade";
    static IS_SHOW_PERCENTAGE = "isShowPercentage";
    static IS_INCLUDE_GPA = "isIncludeGPA";
    static IS_INCLUDE_CLASS_RANK = "isIncludeClassRank";
    static IS_SHOW_TEACHER = "isShowTeacher";
    static IS_TEACHER_COMMENTS_AND_SUGGESTIONS = "isTeacherCommentsAndSuggestions";
    static IS_ATTENDANCE = "isAttendance";
    static IS_DAILY_ATTENDANCE_SUMMARY = "isDailyAttendanceSummary";
    static IS_ATTENDANCE_YEAR_TO_DATE = "isAttendanceYearToDate";
    static IS_ATTENDANCE_CURRENT_MARKING_PERIOD = "isAttendanceCurrentMarkingPeriod";
    static IS_COURSE_WISE_ATTENDANCE_SUMMARY = "isCourseWiseAttendanceSummary";
    static IS_SIGNATURE = "isSignature";
    static IS_CLASS_TEACHER_COMMENTS_AND_SUGGESTIONS = "isClassTeacherCommentsAndSuggestions";
    static IS_PRINCIPAL_SIGNATURE = "isPrincipalSignature";
    static IS_PRINCIPAL_COMMENTS_AND_SUGGESTIONS = "isPrincipalCommentsAndSuggestions";
    static IS_STUDENT_SIGNATURE = "isStudentSignature";
    static IS_STUDENT_DATE = "isStudentDate";
    static IS_PARENT_GUARDIAN_SIGNATURE = "isParentGuardianSignature";
    static IS_PARENT_DATE = "isParentDate";
    static IS_INCLUDE_ISSUE_DATE = "isIncludeIssueDate";
    static IS_SHOW_GENERAL_ITEMS = "isShowGeneralItems";
    static IS_PROMOTION_STATUS = "isPromotionStatus";
    static IS_SHOW_BASE_GRADE_SCALE_DESCRIPTION = "isShowBaseGradeScaleDescription";
    static IS_INCLUDE_DISCIPLINE_RECORDS = "isIncludeDisciplineRecords";
    static IS_CLASS_TEACHER_SIGNATURE = "isClassTeacherSignature";
    static IS_MARKING_PERIOD = "isMarkingPeriod";
    static WEIGHTAGE_TO_FINAL_TRANSCRIPT = "weightageToFinalTranscript";
    static LEA_ID = "leaId";
    static SEA_ID = "seaId";
    static INCLUDE_GRADUATES = "includeWithdrawn";
    static INCLUDE_TRANSCRIPT = "onlyWithTranscript";
    static SESSION_PROFILE_PICTURE = 'markersProfilePicture'
    // Static Values
    static FILE_SIZE_100MB = 104857600;
    static FILE_SIZE_1KB = 1024;
    //
    static COMPONENT_GET_MESSAGE_SENT_LIST = 'v1/adminsendemail/getsentemails';
}
export default commonConfig;
