import React from 'react';
import "react-image-crop/dist/ReactCrop.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import commonConfig from '../commonConfig';
import * as actionsCommon from '../../shared/store/actions/index';
import ExecuteArgs from '../../shared/model/executeArgs';
import CommonFuncs from './markersCommonFunctionComponent';
import { isEqual } from 'lodash';
const commonFuncObj = new CommonFuncs();
class MarkersFileDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: this.props.savedFileList ? this.props.savedFileList : [],
            file: null,
            downloadFlag: false
        }
    }
    getFile = (event, id) => {
        event.preventDefault();
        this.setState({ downloadFlag: true }, () => {
            const endPoint = this.props.hasOwnProperty('endPoint') ? this.props.endPoint : commonConfig.COMPONENT_DOWNLOAD_FILE
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                endPoint + parseInt(id, 10), commonConfig.METHOD_TYPE_GET);
            this.props.onGetFile(executedArgument);
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.savedFileList && !isEqual(nextProps.savedFileList, this.props.savedFileList)) {
            this.setState({ fileList: nextProps.savedFileList }, () => {
                window.$('[data-toggle="tooltip"]').tooltip();
            });
        }
        if (nextProps.getFileUrl && !isEqual(nextProps.getFileUrl, this.props.getFileUrl) && this.state.downloadFlag) {
            this.setState({ downloadFlag: false }, () => {
                commonFuncObj.download(nextProps.getFileUrl.fileName, nextProps.getFileUrl.contentType, nextProps.getFileUrl.fileContent);
                this.props.onResetProps({ name: 'getFileUrl', data: {} });
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="form-group">
                    <label className="col-form-label">{!!this.props.label ? this.props.label : i18n.t('file')}</label>
                    <div className="mt-2 d-flex">
                        {this.state.fileList.length > 0 &&
                            !!this.props.isPreview ?
                            this.state.fileList.map((file,i) => {
                                let imgType = ['jpeg', 'jpg', 'bmp', 'gif', 'png'];
                                let fileName = !!file.fileName ? file.fileName : !!file.filename ? file.filename : file.name
                                let currentFileType = !!fileName ? fileName.split(".").pop().toLowerCase() : commonConfig.DASH_VALUE;
                                let find = imgType.includes(currentFileType);
                                return <div key={i} className={find ? 'preview-image m-b-10 m-r-5 d-inline-block' : 'preview-image m-b-10 m-r-5 d-inline-block preview-icon'} data-toggle="tooltip" data-placement="top" title={fileName}>
                                    <img src={!!file.contents ? file.contents : !!file.thumbnailimage ? file.thumbnailimage : file.thumbnailImage} alt={fileName} className="img-fluid" />
                                    <span className="preview text-truncate">{i18n.t('preview')}
                                        <Link to="#" onClick={(e) => this.getFile(e, !!file.id ? file.id : !!file.fileId ? file.fileId : file.fileid)} className="text-white ml-1"><i className="icon-download"></i></Link>
                                    </span>
                                </div>
                            })
                            :
                            this.state.fileList && this.state.fileList.map((file) => {
                                let fileName = !!file.fileName ? file.fileName : !!file.filename ? file.filename : file.name
                                let pathSplitted = fileName.split('.');
                                let extension = pathSplitted.pop();
                                return <div className="media align-items-center mb-2">
                                    <img className="mr-3" src={process.env.PUBLIC_URL + '/assets/images/file_type/' + extension + '.svg'} width="20" />
                                    <div className="media-body">
                                        <p className="mb-0"><Link to="#" onClick={(e) => this.getFile(e, !!file.id ? file.id : !!file.fileId ? file.fileId : file.fileid)}>{fileName}</Link></p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        getFileUrl: state.commonStore.getFileUrl
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetFile: (executeArgs) => dispatch(actionsCommon.callCommonGetByApi(executeArgs, 'file url')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersFileDownload)