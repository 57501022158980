import * as actionTypesLogin from './actionTypesLogin';
import { SharedApiConfig } from "../../../shared/sharedApiConfig";
import { commonConfig } from '../../commonConfig';
import { intercepter } from '../../../../security/intercepter';
const axios = require('axios');
export const handleLoginFail = (error) => {
    return {
        type: actionTypesLogin.HANDLE_LOGIN_FAIL,
        error: error
    };
};
export const handleLoginPending = (isLoginPending) => {
    return {
        type: actionTypesLogin.HANDLE_LOGIN_PENDING,
        isLoginPending
    };
};
//Validate Login.
export const validaLoginSucess = (user) => {
    return {
        type: actionTypesLogin.VALIDATE_LOGIN_SUCCESS,
        user
    };
};
export const validateLogin = (input) => {
    return dispatch => {
        dispatch(handleLoginPending(true));
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll_VALIDATION,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleLoginPending(false));
            const user = response.data;
            if (user.success && user.data.isValid) {
                user.language = input.postedData.language === '' ? 'en' : input.postedData.language;
                dispatch(validaLoginSucess(user));
            }
            else {
                dispatch(handleLoginFail(user.message == null ? user.data.message : user.message));
            }
        }).catch(err => {
            dispatch(handleLoginPending(false));
            dispatch(handleLoginFail(err));
        });
    };
};


//Get Login Url.
export const getLoginUrlSucess = (loginUrl) => {
    return {
        type: actionTypesLogin.GET_LOGIN_URL_SUCCESS,
        loginUrl
    };
};

export const handleLoginUrlFail = (error) => {
    return {
        type: actionTypesLogin.HANDLE_LOGIN_URL_FAIL,
        error: error
    };
};
export const getLoginUrl = () => {
    return dispatch => {

        axios({
            method: commonConfig.METHOD_TYPE_GET,
            url: SharedApiConfig.API_TO_CAll_FOR_LOGIN_URL,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER
        }).then(function (response) {
            console.log(response)
            const loginUrl = response.data;
            dispatch(getLoginUrlSucess(loginUrl));

        }).catch(err => {
            dispatch(handleLoginUrlFail(err));
        });
    };
};

export const getResourceSucess = (resources) => {
    return {
        type: actionTypesLogin.GET_RESOURCES_SUCESS,
        resources
    };
};
export const getResourceDetails = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            if (resources.success) {
                dispatch(getResourceSucess(resources));
            }
            else {
                dispatch(handleLoginFail(resources.message));
            }
        }).catch(err => {
            dispatch(handleLoginPending(false));
            dispatch(handleLoginFail(err));
        });
    };
};
// for menu
export const getAllMenusSuccess = (custom) => {
    return {
        type: actionTypesLogin.GET_ALL_MENUS_SUCCESS,
        custom: custom
    };
};
export const getAllMenus = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            if (resources.success) {
                dispatch(getAllMenusSuccess(resources));
            }
            else {
                dispatch(handleLoginFail(resources.message));
            }
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
export const getUserMenusSuccess = (custom) => {
    return {
        type: actionTypesLogin.GET_USER_MENUS_SUCCESS,
        custom: custom
    };
};
export const getUserMenus = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            if (resources.success) {
                dispatch(getUserMenusSuccess(resources));
            }
            else {
                dispatch(handleLoginFail(resources.message));
            }
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
// notifications
export const getAllNotificationSuccess = (custom) => {
    return {
        type: actionTypesLogin.GET_ALL_NOTIFICATION_SUCCESS,
        custom: custom
    };
};
export const getAllNotifications = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(getAllNotificationSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
//MarkAsRead
export const updateMarkAsReadNotificationSuccess = (custom) => {
    return {
        type: actionTypesLogin.UPDATE_MARKASREAD_NOTIFICATION_SUCCESS,
        custom: custom
    };
};
export const updateMarkAsReadNotification = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(updateMarkAsReadNotificationSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
//Update User prefernce success
export const saveUserPreferenceSuccess = (custom) => {
    return {
        type: actionTypesLogin.UPDATE_USER_PREFERENCE_SUCCESS,
        custom: custom
    };
};
export const saveUserPreference = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(saveUserPreferenceSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};

export const getUserPreferenceOtpSuccess = (custom) => {
    return {
        type: actionTypesLogin.GET_USER_PREFERENCE_SUCCESS,
        custom: custom
    };
};
export const getUserPreferenceOtp = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(getUserPreferenceOtpSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
//Get Last Session Of a User
export const getLastSessionOfaUserSucess = (resources) => {
    return {
        type: actionTypesLogin.GET_LAST_SESSION_SUCESS,
        resources
    };
};
export const getLastSessionOfaUser = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(getLastSessionOfaUserSucess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
//Set Last Session Of a User
export const setLastSessionOfaUserSucess = (resources) => {
    return {
        type: actionTypesLogin.SET_LAST_SESSION_SUCESS,
        resources
    };
};
export const setLastSessionOfaUser = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(setLastSessionOfaUserSucess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
export const loginStoreStateReset = input => {
    return {
        type: commonConfig.LOGIN_STORE_RESET,
        custom: input,
        title: input.name
    };
};
//Forgot password
export const getForgotPasswordOtpSuccess = (custom) => {
    return {
        type: actionTypesLogin.GET_FORGOT_PASSWORD_OTP_SUCCESS,
        custom: custom
    };
};
export const getForgotPasswordOtp = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll_VALIDATION,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(getForgotPasswordOtpSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
export const confirmForgotPasswordOtpSuccess = (custom) => {
    return {
        type: actionTypesLogin.CONFIRM_FORGOT_PASSWORD_OTP_SUCCESS,
        custom: custom
    };
};
export const confirmForgotPasswordOtp = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll_VALIDATION,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(confirmForgotPasswordOtpSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
export const saveConfirmPasswordSuccess = (custom) => {
    return {
        type: actionTypesLogin.SAVE_CONFIRM_PASSWORD_SUCCESS,
        custom: custom
    };
};
export const saveConfirmPassword = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll_VALIDATION,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(saveConfirmPasswordSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};

//generateStudentPasswordSchedulerSuccess



export const generateStudentPasswordSchedulerSuccess = (custom) => {
    return {
        type: actionTypesLogin.GENERATE_STUDENT_SCHEDULER_PASSWORD_SUCCESS,
        custom: custom
    };
};

export const generateStudentPasswordScheduler = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        })
            .then(function (response) {
                const resources = response.data;
                dispatch(generateStudentPasswordSchedulerSuccess(resources));
            }).catch(err => {
                dispatch(handleLoginFail(err));
            });
    };
}
// get system maintenance by user id
export const getSystemMaintenanceByUserIdSuccess = (custom) => {
    return {
        type: actionTypesLogin.GET_SYSTEM_MAINTENANCE_USER_ID_SUCCESS,
        custom: custom
    };
};
export const getSystemMaintenanceByUserId = (input) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            const resources = response.data;
            dispatch(getSystemMaintenanceByUserIdSuccess(resources));
        }).catch(err => {
            dispatch(handleLoginFail(err));
        });
    };
};
